import { Ref } from 'react';
import { Box, TextFieldProps as MuiTextFieldProps } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { FieldValues, FieldPath } from 'react-hook-form';
import { BaseSelectField, BaseSelectFieldProps } from './BaseSelectField';
import { FieldLabel } from '../FieldLabel';
import {
  CommonFieldProps,
  mergeRequiredIntoRules,
} from '../common/CommonFieldProps';

export type SelectFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = CommonFieldProps<TFieldValues, TName> &
  Pick<BaseSelectFieldProps<TFieldValues, TName>, 'options'> & {
    placeholder?: string;
    inputProps?: MuiTextFieldProps['inputProps'];
    inputRef?: Ref<HTMLInputElement>;
  };

export const SelectField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  control,
  name,
  options,
  label,
  hideRequiredMark,
  required,
  rules,
  gutterBottom,
  disabled,
  error,
  helperText,
  tooltipContent,
  containerRef,
  onChange,
  placeholder,
  inputProps,
  inputRef = undefined,
}: SelectFieldProps<TFieldValues, TName>): JSX.Element => {
  const mergedRules = mergeRequiredIntoRules(required, rules);

  return (
    <Box width="100%" mb={gutterBottom ? 2 : 0}>
      {label && (
        <FieldLabel
          label={label}
          required={!!mergedRules?.required}
          hideRequiredMark={hideRequiredMark}
          tooltipContent={tooltipContent}
        />
      )}
      <BaseSelectField
        control={control}
        name={name}
        options={options}
        rules={mergedRules}
        variant="outlined"
        fullWidth
        InputProps={{ style: { backgroundColor: grey[50] } }}
        inputProps={{ 'aria-label': label, ...inputProps }}
        disabled={disabled}
        error={error}
        helperText={helperText}
        containerRef={containerRef}
        onChange={onChange}
        placeholder={placeholder}
        inputRef={inputRef}
        size="small"
      />
    </Box>
  );
};
