import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogContent,
  DialogActions,
  DialogTitle,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { forwardRef } from 'react';
import { Button, ButtonProps } from 'components/buttons';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

export type ActionButton = Omit<ButtonProps, 'children'> & {
  label: string;
};

export type DialogProps = MuiDialogProps & {
  title?: string;
  actionButtons?: ActionButton[];
  breakPoint?: Breakpoint;
};

const useStyles = makeStyles(() => ({
  dialogActions: {
    padding: 24,
  },
  dialogContent: {
    padding: '8px 24px',
  },
}));

export const Dialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    { title, actionButtons, breakPoint = 'xs', children, ...rest }: DialogProps,
    ref,
  ) => {
    const classes = useStyles();
    const theme = useTheme();
    const isFullScreen = useMediaQuery(theme.breakpoints.down(breakPoint));
    const buttons = actionButtons?.map((attribute, index) => {
      const { label, ...rest } = attribute;
      return (
        <Button key={index} {...rest}>
          {label}
        </Button>
      );
    });

    return (
      <MuiDialog title={title} ref={ref} {...rest} fullScreen={isFullScreen}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {children}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {buttons}
        </DialogActions>
      </MuiDialog>
    );
  },
);

Dialog.displayName = 'Dialog';
