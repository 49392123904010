import { Ref, ChangeEvent } from 'react';
import {
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  Radio,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import { FieldValues, FieldPath, PathValue } from 'react-hook-form';
import { useController, Control, RegisterOptions } from 'react-hook-form';

export type RadioOption = {
  label: string;
  value: string;
};

const useStyles = makeStyles(() => ({
  readOnlyLabel: {
    color: 'rgba(0, 0, 0, 0.5)',
    cursor: 'default',
  },
}));

export type BaseRadioGroupProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  control: Control<TFieldValues>;
  name: TName;
  options: RadioOption[];
  defaultValue?: PathValue<TFieldValues, TName>;
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  convertToString?: (value?: PathValue<TFieldValues, TName>) => string;
  convertFromString?: (value: string) => PathValue<TFieldValues, TName>;
  containerRef?: Ref<HTMLDivElement> | undefined;
} & Omit<MuiRadioGroupProps, 'onChange'> & {
    disabled?: boolean;
    readOnly?: boolean;
    onChange?: (value: PathValue<TFieldValues, TName>) => unknown;
  };

export const BaseRadioGroup = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  control,
  name,
  options,
  defaultValue,
  rules,
  convertToString = identityFn as (
    value?: PathValue<TFieldValues, TName>,
  ) => string,
  convertFromString = identityFn as (
    value: string,
  ) => PathValue<TFieldValues, TName>,
  containerRef,
  disabled = false,
  readOnly = false,
  onChange,
  ...rest
}: BaseRadioGroupProps<TFieldValues, TName>): JSX.Element => {
  const classes = useStyles();
  const { field } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (readOnly) {
      return false;
    }

    const convertedValue = convertFromString(e.target.value);
    field.onChange(convertedValue);
    if (onChange != null) {
      onChange(convertedValue);
    }
  };

  return (
    <MuiRadioGroup
      {...rest}
      {...field}
      value={convertToString(field.value as PathValue<TFieldValues, TName>)}
      onChange={handleChange}
      ref={containerRef}
    >
      {options.map(({ label, value }, index) => (
        <FormControlLabel
          key={index}
          value={value}
          label={label}
          control={<Radio />}
          disabled={disabled}
          className={readOnly ? classes.readOnlyLabel : undefined}
        />
      ))}
    </MuiRadioGroup>
  );
};

const identityFn = (v: string): string => v ?? '';
