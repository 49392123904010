import { Box } from '@material-ui/core';
import { Button } from 'components/buttons/Button';
import {
  TodoActivityLogList_TodoFragment,
  TodoActivityType,
} from 'generated/graphql';
import { Comment } from './Comment';
import { Action } from './Action';
import { existenceFilter } from 'utils/type-filter';
import { baseBackgroundGrey } from 'color-palette';

type Props = {
  todo: TodoActivityLogList_TodoFragment;
  toggleHideExceptComments: () => void;
  displayAllTodoActivityLogs: boolean;
};

export const TodoActivityLogList = ({
  todo,
  toggleHideExceptComments,
  displayAllTodoActivityLogs,
}: Props): JSX.Element => {
  const logs = todo.todoActivityLogs?.collection.filter(existenceFilter) ?? [];

  return (
    <>
      <Box textAlign="right">
        {logs.some(
          (log) => log.activityType === TodoActivityType.Commented,
        ) && (
          <Button
            onClick={toggleHideExceptComments}
            variant="outlined"
            color="default"
          >
            {displayAllTodoActivityLogs
              ? 'コメントのみ表示'
              : '全アクティビティ表示'}
          </Button>
        )}
      </Box>
      <Box bgcolor={baseBackgroundGrey}>
        <Box component="h3" pt={2} pl={2}>
          タイムライン
        </Box>
        <Box px={2} pb={2}>
          {logs.map((log) =>
            log.activityType === TodoActivityType.Commented ? (
              <Comment activityLog={log} />
            ) : (
              displayAllTodoActivityLogs && <Action activityLog={log} />
            ),
          )}
        </Box>
      </Box>
    </>
  );
};
