import { Ref } from 'react';
import { Box, TextFieldProps as MuiTextFieldProps } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { FieldValues, FieldPath } from 'react-hook-form';
import { FieldLabel } from '../FieldLabel';
import { BaseTextField } from './BaseTextField';
import {
  CommonFieldProps,
  mergeRequiredIntoRules,
} from '../common/CommonFieldProps';

type ValueType = 'text' | 'password' | 'email';

export type TextFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = CommonFieldProps<TFieldValues, TName> & {
  type?: ValueType;
  multiline?: boolean;
  readOnly?: boolean;
  minRows?: number;
  rows?: number;
  maxRows?: number;
  placeholder?: string;
  inputProps?: MuiTextFieldProps['inputProps'];
  inputRef?: Ref<HTMLInputElement>;
};

export const TextField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  control,
  name,
  label,
  hideRequiredMark,
  required,
  rules,
  gutterBottom,
  disabled,
  readOnly = false,
  error,
  helperText,
  tooltipContent,
  containerRef,
  onChange,
  type = 'text',
  multiline,
  minRows = 3,
  maxRows,
  placeholder,
  inputProps,
  inputRef,
}: TextFieldProps<TFieldValues, TName>): JSX.Element => {
  const mergedRules = mergeRequiredIntoRules(required, rules);

  return (
    <Box width="100%" mb={gutterBottom ? 2 : 0}>
      {label && (
        <FieldLabel
          label={label}
          required={!!mergedRules?.required}
          hideRequiredMark={hideRequiredMark}
          tooltipContent={tooltipContent}
        />
      )}
      <BaseTextField
        control={control}
        name={name}
        rules={mergedRules}
        variant="outlined"
        fullWidth
        InputProps={{ style: { backgroundColor: grey[50] } }}
        inputProps={{ 'aria-label': label, readOnly: readOnly, ...inputProps }}
        disabled={disabled}
        error={error}
        helperText={helperText}
        containerRef={containerRef}
        onChange={onChange}
        type={type}
        multiline={multiline}
        minRows={multiline ? minRows : undefined}
        maxRows={multiline ? maxRows : undefined}
        placeholder={placeholder}
        inputRef={inputRef}
        size="small"
      />
    </Box>
  );
};
