import { ReactChild } from 'react';
import { Box, useTheme } from '@material-ui/core';
import { HelpIconTooltip } from 'components/Tooltip/HelpIconTooltip';

export type FieldLabelProps = {
  label: string;
  required: boolean;
  hideRequiredMark?: boolean;
  tooltipContent?: ReactChild;
};

export const FieldLabel = ({
  label,
  required,
  hideRequiredMark = false,
  tooltipContent,
}: FieldLabelProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Box display="flex" mb={0.5} alignItems="center">
      <Box>
        <label>{label}</label>
      </Box>
      {!hideRequiredMark && (
        <Box
          bgcolor={required ? palette.primary.main : palette.grey[500]}
          color="white"
          ml={1}
          pl={0.5}
          pr={0.5}
          pt={0.25}
          pb={0}
          borderRadius={2}
          fontSize="0.8em"
        >
          {required ? '必須' : '任意'}
        </Box>
      )}
      <Box mr={1} />
      {tooltipContent && <HelpIconTooltip tooltipContent={tooltipContent} />}
    </Box>
  );
};
