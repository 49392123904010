import * as gtag from './lib/gtag';

export const gtagSubmit = (label?: string) => {
  if (!gtag.GA_ID) {
    return;
  }

  gtag.event({
    action: 'click',
    category: 'submit',
    label: label ?? '',
  });
  return;
};
