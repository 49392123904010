import { useState, useEffect, Ref, ChangeEvent } from 'react';
import {
  useController,
  Control,
  RegisterOptions,
  FieldValues,
  FieldPath,
  PathValue,
} from 'react-hook-form';
import {
  Tooltip,
  Box,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core';
import { decimalAdjust } from 'utils/number/decimalAdjust';

export type BaseNumberFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  control: Control<TFieldValues>;
  name: TName;
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  allowNegative?: boolean; // 負の数を許す
  maximumFractionDigits?: number; // 小数点以下の桁数（今は最大値だけど、有効桁数の概念導入すると変わるかも）
  containerRef?: Ref<HTMLDivElement> | undefined;
} & Omit<MuiTextFieldProps, 'value' | 'defaultValue' | 'onChange'> & {
    onChange?: (value: PathValue<TFieldValues, TName>) => unknown;
    // defaultValueはinputではなくControllerに渡すもの（Controlled inputなのでinput.defaultValueは使わない）
    // field arrayを利用する時には必須
    defaultValue?: PathValue<TFieldValues, TName>;
  };

export const BaseNumberField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  control,
  name,
  rules,
  onChange,
  defaultValue,
  inputProps,
  allowNegative = false,
  maximumFractionDigits = 0,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  containerRef,
  ...props
}: BaseNumberFieldProps<TFieldValues, TName>): JSX.Element => {
  const mergedInputProps = {
    inputMode: 'decimal',
    pattern: '-?[0-9,.]*',
    ...inputProps,
    style: { textAlign: 'right', ...inputProps?.style },
  } as const;

  const { field } = useController({ name, control, rules, defaultValue });
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [displayValue, setDisplayValue] = useState(
    formatNumber(field.value as number, maximumFractionDigits),
  );

  useEffect(() => {
    // 外部から書き換えられた場合に対応

    // 現在のdisplayValueをパースして、その値(number)とfield.valueに差異がある時だけ
    // displayValueを更新する

    const parsed = parseNumber(
      displayValue,
      allowNegative,
      maximumFractionDigits,
    );

    if (field.value !== parsed) {
      setDisplayValue(
        formatNumber(field.value as number, maximumFractionDigits),
      );
    }

    // eslint-disable-next-line
  }, [field.value]);

  const tooltipValue =
    field.value != null
      ? formatNumber(field.value as number, maximumFractionDigits)
      : '-';

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDisplayValue(e.target.value);

    const parsed = parseNumber(
      e.target.value,
      allowNegative,
      maximumFractionDigits,
    );
    const newValue = Number.isNaN(parsed) ? null : parsed;

    if ((field.value ?? null) !== newValue) {
      field.onChange(newValue);

      if (onChange) onChange(newValue as PathValue<TFieldValues, TName>);
    }
  };

  const handleFocus = () => {
    setTooltipOpen(true);
  };

  const handleBlur = () => {
    setTooltipOpen(false);

    const value = field.value as number;

    setDisplayValue(formatNumber(value, maximumFractionDigits));
    field.onBlur();
  };

  return (
    <Tooltip
      title={<Box fontSize="1rem">{tooltipValue}</Box>}
      open={tooltipOpen}
      onClose={() => setTooltipOpen(false)}
      onOpen={() => setTooltipOpen(true)}
      disableHoverListener
      disableTouchListener
      disableFocusListener
      placement="bottom-end"
      arrow
    >
      <MuiTextField
        autoComplete="off"
        {...props}
        value={displayValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        inputProps={mergedInputProps}
      />
    </Tooltip>
  );
};

// 余計な文字を除いて可能な限りパースしようと試みるパーサ
const parseNumber = (
  s: string,
  allowNegative: boolean,
  maximumFractionDigits: number,
): number => {
  // 全角を半角に変換
  s = s.replace(/[０-９．－]/g, (str) =>
    String.fromCharCode(str.charCodeAt(0) - 0xfee0),
  );

  // 「。」はピリオドに
  s = s.replace(/。/g, '.');

  // 長音記号や全角マイナスっぽいものを変換
  s = s.replace(/[ー−]/g, '-');

  if (!allowNegative) {
    s = s.replace(/^-/, '');
  }

  // コンマを削除
  s = s.replace(/,/g, '');

  // 数字とマイナスとピリオド以外の文字を削除
  s = s.replace(/[^-0-9.]/g, '');

  const parsed = parseFloat(s);

  if (Number.isNaN(parsed)) return parsed;

  return floorToZero(parsed, -maximumFractionDigits);
};

const formatNumber = (
  v: number | null | undefined,
  maximumFractionDigits: number,
) => {
  if (v == null || Number.isNaN(v)) return '';
  return v.toLocaleString('ja-JP', { maximumFractionDigits });
};

// ゼロ方向に丸める
function floorToZero(value: number, exp: number): number {
  return value >= 0
    ? decimalAdjust('floor', value, exp)
    : decimalAdjust('ceil', value, exp);
}
