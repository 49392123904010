import { useLoginMember } from 'hooks/useLoginUser';
import { ScreenCode, DisplayItemCode } from 'generated/graphql';

const defaultDisplayItems = [
  DisplayItemCode.SalesSummaryByContractDate,
  DisplayItemCode.SummaryOrderBreakdownByCustomer,
];

type HookReturnType = {
  displayItemCodes: DisplayItemCode[];
};

export const useDashboard = (): HookReturnType => {
  const loginUser = useLoginMember();
  const dashboardSettings = loginUser?.company.companyDashboardSettings;
  const settingOnTop = dashboardSettings?.find(
    (setting) => setting.screenCode === ScreenCode.Top,
  );
  const displayItemCodes =
    settingOnTop?.displayItemCodes ?? defaultDisplayItems;

  return {
    displayItemCodes,
  };
};
