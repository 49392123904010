import { EditTodoForm_TodoFragment as Todo } from 'generated/graphql';
import { DefaultValues } from 'react-hook-form';
import { fromDateTimeString } from 'utils/date';
import { TodoFormValues } from '../TodoForm/TodoFormValues';

type Props = {
  todo: Todo | undefined;
};

export const buildDefaultValues = ({
  todo,
}: Props): DefaultValues<TodoFormValues> => {
  if (!todo)
    return {
      title: '',
      description: null,
      projectPhaseId: null,
      assigneeIds: [],
      expiredAt: null,
    };

  const { title, description, projectPhase, assignees, expiredAt } = todo;

  return {
    title: title,
    description: description,
    projectPhaseId: projectPhase?.id,
    assigneeIds: assignees?.map((assignee) => assignee.id) ?? [],
    expiredAt: expiredAt ? fromDateTimeString(expiredAt) : undefined,
  };
};
