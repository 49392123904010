import { makeStyles } from '@material-ui/core';
import { Favorite as FavoriteIcon } from '@material-ui/icons';
import {
  ListTable,
  ListTableHeader,
  ListTableRowData,
} from 'components/ListTable';
import { crassoneRed } from 'color-palette';
import {
  CustomerSelectDialog_CustomerFragment,
  CustomerSelectDialog_CustomerList_CustomersFragment,
  PaginationNav_PageInfoFragment,
} from 'generated/graphql';

type Props = {
  customers: CustomerSelectDialog_CustomerList_CustomersFragment[];
  onSelect: (customer: CustomerSelectDialog_CustomerFragment) => unknown;
  pageInfo: PaginationNav_PageInfoFragment;
  onPageChange: (page: number) => unknown;
};

const useStyles = makeStyles((theme) => ({
  mainCustomerIcon: {
    color: crassoneRed.light,
    verticalAlign: 'middle',
    marginLeft: theme.spacing(0.5),
  },
}));

export const CustomerListTable = ({
  customers,
  onSelect,
  pageInfo,
  onPageChange,
}: Props): JSX.Element => {
  const classes = useStyles();
  const header: ListTableHeader = [
    '顧客名',
    { content: '案件数', cellProps: { align: 'right', width: 120 } },
  ];
  const rows: ListTableRowData[] = customers.map((customer) => {
    return {
      onClick: () => onSelect(customer),
      hover: true,
      linkStyle: true,
      cells: [
        <>
          {customer.name}
          {customer.isMainCustomer && (
            <FavoriteIcon
              fontSize="small"
              className={classes.mainCustomerIcon}
            />
          )}
        </>,
        { content: customer.projectCount, cellProps: { align: 'right' } },
      ],
    };
  });

  return (
    <div>
      <ListTable
        containerComponent="div"
        header={header}
        rows={rows}
        pageInfo={pageInfo}
        onPageChange={onPageChange}
      />
    </div>
  );
};
