import { Box, makeStyles } from '@material-ui/core';
import { H2 } from 'components/presentation/headers/';
import { HelpIconTooltip } from 'components/Tooltip/HelpIconTooltip';
import { LoadError, LoadingSpinner } from 'components/notification';
import { useSalesSummaryByContractDate } from './useSalesSummaryByContractDate';
import { SalesSummaryChart } from 'components/SalesSummaryChart';

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: theme.spacing(4),
  },
}));

export const SalesSummaryByContractDate = (): JSX.Element => {
  const classes = useStyles();

  const { salesSummary, loadError, loading } = useSalesSummaryByContractDate();

  return (
    <>
      <H2>
        月別の実績
        <HelpIconTooltip
          tooltipContent={
            <>
              月ごとの売上高・受注高・受注数
              <br />※ 売上高 : 受注日を基準にした請負金額(税抜)の合計
            </>
          }
        />
      </H2>
      {loadError ? (
        <LoadError error={loadError} />
      ) : salesSummary == undefined || loading ? (
        <LoadingSpinner />
      ) : salesSummary?.length ? (
        <Box className={classes.box}>
          <SalesSummaryChart salesSummary={salesSummary} />
        </Box>
      ) : (
        <div style={{ height: 300, padding: 50 }}>
          表示できるデータがありません。
        </div>
      )}
    </>
  );
};
