import { useProjectPhaseSelectorQuery } from 'generated/graphql';
import { companyContext as context } from 'apolloClient';
import { ChangeEvent, useState, useEffect } from 'react';

type Props = {
  value: string | null;
  onChange: (projectPhaseId: string | null) => void;
};

type HookReturnType = {
  loading: boolean;
  loadError: Error | undefined;
  options: { label: string; value: string }[];
  selectedPhase: { label: string; value: string } | null;
  handleChange: (
    _event: ChangeEvent<unknown>,
    value: {
      label: string;
      value: string;
    } | null,
  ) => void;
};

export const useProjectPhaseSelector = ({
  value,
  onChange,
}: Props): HookReturnType => {
  const { data, loading, error: loadError } = useProjectPhaseSelectorQuery({
    context,
  });

  const [selectedPhase, setSelectedPhase] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const projectPhases = data?.listProjectPhases || [];
  const options = [
    { label: '未選択', value: '' },
    ...projectPhases.map((phase) => ({
      label: phase.name,
      value: phase.id,
    })),
  ];

  useEffect(() => {
    if (projectPhases) {
      const phase = projectPhases.find((phase) => phase.id === value);
      if (phase) {
        setSelectedPhase(value ? { label: phase.name, value: phase.id } : null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectPhases]);

  const handleChange = (
    _event: ChangeEvent<unknown>,
    value: {
      label: string;
      value: string;
    } | null,
  ) => {
    setSelectedPhase(value ?? null);
    onChange(value ? value.value : null);
  };

  return {
    loading,
    loadError,
    options,
    selectedPhase,
    handleChange,
  };
};
