import { Box } from '@material-ui/core';
import {
  TodoActivityLogList_LogFragment,
  TodoActivityLogList_CommentFragment,
} from 'generated/graphql';
import { formatDateTime, FormatDateTimeOption } from 'utils/date';
import { borderGrey, white } from 'color-palette';

type Props = {
  activityLog: TodoActivityLogList_LogFragment;
};

export const Comment = ({ activityLog }: Props): JSX.Element => {
  const comment = activityLog.activityTarget as TodoActivityLogList_CommentFragment;

  return (
    <Box mb={3}>
      <Box>
        {comment.member?.name && `${comment.member.name}さんが`}
        コメントしました。
        <Box fontSize="0.9em">
          {formatDateTime(activityLog.occurredAt, FormatDateTimeOption.slash)}
        </Box>
        <Box
          border={`${borderGrey} 1px solid`}
          bgcolor={white}
          p={1}
          whiteSpace="pre-wrap"
        >
          {comment.commentText}
        </Box>
      </Box>
    </Box>
  );
};
