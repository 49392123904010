import { ReactChild } from 'react';
import { Box } from '@material-ui/core';
import { borderGrey } from 'color-palette';

export type AttributesTableItemData = {
  label: string;
  value: ReactChild | undefined | null;
  action?: ReactChild | undefined | null;
};

export type AttributesTableItemProps = AttributesTableItemData & {
  labelWidth?: number;
};

// これ単体でも使えるようにする
export const AttributesTableItem = ({
  label,
  value,
  action,
  labelWidth = 100,
}: AttributesTableItemProps): JSX.Element => {
  return (
    <Box
      display="flex"
      mb={1}
      minHeight={30}
      alignItems="baseline"
      borderBottom={`1px solid ${borderGrey}`}
      whiteSpace="pre-line"
    >
      <Box width={labelWidth} fontSize="0.8rem" fontWeight={600}>
        {label}
      </Box>
      <Box flexGrow={1} fontSize="1rem">
        {value ?? '-'}
      </Box>
      <Box>{action}</Box>
    </Box>
  );
};
