// 以下を元にtsに改変
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/floor

/**
 * Decimal adjustment of a number.
 *
 * @param {String}  type  The type of adjustment.
 * @param {Number}  value The number.
 * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
 * @returns {Number} The adjusted value.
 */
export function decimalAdjust(
  type: 'round' | 'floor' | 'ceil',
  value: number,
  exp: number,
): number {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  let [i, f] = value.toString().split('e');
  value = Math[type](+(i + 'e' + (f ? +f - exp : -exp)));
  // Shift back
  [i, f] = value.toString().split('e');
  return +(i + 'e' + (f ? +f + exp : exp));
}
