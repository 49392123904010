import { useMemberSelectorQuery } from 'generated/graphql';
import { companyContext as context } from 'apolloClient';
import { ChangeEvent, useState, useEffect } from 'react';
import { existenceFilter } from 'utils/type-filter';

type Props = {
  values: string[];
  onChange: (memberIds: string[]) => void;
};

type HookReturnType = {
  loading: boolean;
  loadError: Error | undefined;
  options: { label: string; value: string }[];
  selectedMembers: { label: string; value: string }[];
  handleChange: (
    _event: ChangeEvent<unknown>,
    value: {
      label: string;
      value: string;
    }[],
  ) => void;
};

export const useMemberSelector = ({
  values,
  onChange,
}: Props): HookReturnType => {
  const { data, loading, error: loadError } = useMemberSelectorQuery({
    variables: {
      limit: 200,
    },
    context,
  });

  const [selectedMembers, setSelectedMembers] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const members = data?.companyWholeMembers.collection;
  const options =
    members?.map((member) => ({
      label: member.name,
      value: member.id,
    })) ?? [];

  useEffect(() => {
    if (members) {
      setSelectedMembers(
        values
          .map((id) => members.find((member) => member.id === id))
          .filter(existenceFilter)
          .map((member) => ({ label: member.name, value: member.id })),
      );
    }
  }, [members, values]);

  const handleChange = (
    _event: ChangeEvent<unknown>,
    values: {
      label: string;
      value: string;
    }[],
  ) => {
    const selectedIds = values.map((v) => v.value);
    setSelectedMembers(values);
    onChange(selectedIds);
  };

  return {
    loading,
    loadError,
    options,
    selectedMembers,
    handleChange,
  };
};
