import type { ReactNode } from 'react';
import { Box } from '@material-ui/core';

export type ContainerProps = {
  children: ReactNode;
};

export const ContainerWithoutBgColor = ({
  children,
}: ContainerProps): JSX.Element => {
  return <Box maxWidth={1280}>{children}</Box>;
};
