import { isValidElement, ReactChild, ElementType } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TableCellProps,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { LoadingSpinner } from 'components/notification';
import { PaginationNav, PageInfo } from 'components/pagination';
import { TableCell } from './TableCell';
import { ListTableRow, ListTableRowData } from './ListTableRow';
import { TableSortLabel } from './TableSortLabel';
import { isReactText } from './isReactText';
import { SortOrder } from 'generated/graphql';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('xs')]: {
        overflow: 'auto',
        whiteSpace: 'nowrap',
      },
    },
  }),
);

export type ListTableHeaderItem =
  | ReactChild
  | {
      content: ReactChild;
      sortable?: boolean;
      sortKey?: string;
      onSort?: (order: SortOrder) => unknown;
      cellProps?: TableCellProps;
    };

export type ListTableHeader = ListTableHeaderItem[];

export type ListTableProps = {
  header: ListTableHeader;
  rows: ListTableRowData[];
  loading?: boolean;
  pageInfo: PageInfo | null | undefined;
  onPageChange: (page: number) => void;
  containerComponent?: ElementType;
  sortable?: boolean;
  sortKey?: string;
  sortOrder?: SortOrder;
};

export const ListTable = ({
  header,
  rows,
  loading = false,
  pageInfo,
  onPageChange,
  containerComponent = Paper,
  sortable = false,
  sortKey,
  sortOrder = SortOrder.Asc,
}: ListTableProps): JSX.Element => {
  const classes = useStyles();
  return (
    <TableContainer
      component={containerComponent}
      className={classes.container}
    >
      <Table component="div">
        <TableHead component="div">
          <TableRow component="div">
            {header.map((item, index) => {
              if (isReactText(item) || isValidElement(item)) {
                item = { content: item };
              }

              if (isReactText(item.content)) {
                item.content = <b>{item.content}</b>;
              }

              if (sortable && item.sortable) {
                const { sortKey: itemSortKey, onSort } = item;
                if (itemSortKey == null || onSort == null) {
                  if (process.env.NODE_ENV === 'development') {
                    console.error(
                      'sortable===trueの時、sortKey, onSortは必須です。',
                      { index, item },
                    );
                  }
                  throw new Error('無効なデータです。');
                }

                const isActive = sortKey === item.sortKey;

                return (
                  <TableCell key={index} {...item.cellProps}>
                    <TableSortLabel
                      active={isActive}
                      direction={isActive ? sortOrder : SortOrder.Asc}
                      onSort={onSort}
                    >
                      {item.content}
                    </TableSortLabel>
                  </TableCell>
                );
              }

              return (
                <TableCell key={index} {...item.cellProps}>
                  {item.content}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        {!loading && (
          <TableBody component="div">
            {rows.map((row, index) => (
              <ListTableRow key={index} row={row} />
            ))}
          </TableBody>
        )}
      </Table>
      {loading && (
        <Box p={6}>
          <LoadingSpinner />
        </Box>
      )}
      {!loading && pageInfo && (
        <Box py={1}>
          <PaginationNav pageInfo={pageInfo} onPageChange={onPageChange} />
        </Box>
      )}
    </TableContainer>
  );
};
