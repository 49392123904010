import { useState } from 'react';
import {
  CustomerSelectDialog_CustomerFragment,
  CustomerSelectDialog_CustomerList_CustomersFragment,
  useCustomerSelectDialog_CustomerListQuery,
  CustomerSearchInput,
  CustomerType,
  PaginationNav_PageInfoFragment,
} from 'generated/graphql';

const context = { clientName: 'company' };

type Props = {
  onSelect: (customer: CustomerSelectDialog_CustomerFragment) => unknown;
  includesContactInfo: boolean;
};

export const useCustomerList = ({
  onSelect,
  includesContactInfo,
}: Props): {
  pageInfo: PaginationNav_PageInfoFragment | undefined;
  handlePageChange: (page: number) => unknown;
  customers: CustomerSelectDialog_CustomerList_CustomersFragment[];
  handleFilterChange: (values: CustomerSearchInput) => unknown;
  handleCustomerSelect: (
    customer: CustomerSelectDialog_CustomerFragment,
  ) => unknown;
  loading: boolean;
  loadError: Error | undefined;
} => {
  const [searchConditions, setSearchConditions] = useState<CustomerSearchInput>(
    {
      keyword: '',
      isMainCustomer: false,
      customerType: CustomerType.Corporate,
    },
  );
  const [page, setPage] = useState(1);

  const {
    data,
    error: loadError,
    loading,
  } = useCustomerSelectDialog_CustomerListQuery({
    variables: { searchConditions, page, limit: 10, includesContactInfo },
    context,
  });

  const customers = data?.companyListCustomers.collection ?? [];
  const pageInfo = data?.companyListCustomers.pageInfo;

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleFilterChange = (searchCondition: CustomerSearchInput) => {
    setSearchConditions(searchCondition);
    setPage(1);
  };

  const handleCustomerSelect = (
    customer: CustomerSelectDialog_CustomerFragment,
  ) => {
    onSelect(customer);
  };

  return {
    pageInfo,
    handlePageChange,
    customers,
    handleFilterChange,
    handleCustomerSelect,
    loading,
    loadError,
  };
};
