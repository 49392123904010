import { Box, FormControl, FormHelperText } from '@material-ui/core';
import { RegisterOptions, FieldValues, FieldPath } from 'react-hook-form';
import { BaseRadioGroup, BaseRadioGroupProps } from './BaseRadioGroup';
import { FieldLabel } from '../FieldLabel';
import { mergeRequiredIntoRules } from '../common/CommonFieldProps';
import { ReactChild } from 'react';

export type RadioGroupProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Pick<
  BaseRadioGroupProps<TFieldValues, TName>,
  'control' | 'name' | 'defaultValue' | 'options' | 'onChange'
> & {
  label?: string;
  row?: boolean;
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  required?: boolean;
  gutterBottom?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  helperText?: string;
  tooltipContent?: ReactChild;
  hideRequiredMark?: boolean;
};

export const RadioGroup = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  control,
  name,
  label,
  defaultValue,
  options,
  row = false,
  rules,
  required = false,
  gutterBottom = false,
  disabled = false,
  readOnly = false,
  onChange,
  error,
  helperText,
  tooltipContent,
  hideRequiredMark,
}: RadioGroupProps<TFieldValues, TName>): JSX.Element => {
  const mergedRules = mergeRequiredIntoRules(required, rules);

  return (
    <Box width="100%" mb={gutterBottom ? 2 : 0}>
      <FormControl component="fieldset" error={error}>
        {label && (
          <FieldLabel
            label={label}
            required={!!mergedRules?.required}
            hideRequiredMark={hideRequiredMark}
            tooltipContent={tooltipContent}
          />
        )}
        <BaseRadioGroup
          name={name}
          control={control}
          defaultValue={defaultValue}
          options={options}
          row={row}
          rules={mergedRules}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onChange}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};
