import { ActionButton, ActionButtonProps } from '../ActionButton';
import { gtagSubmit } from 'gtagSubmit';

export type SubmitButtonProps = ActionButtonProps;

export const SubmitButton = ({
  children,
  variant = 'contained',
  color = 'primary',
  onClick,
  ...rest
}: SubmitButtonProps): JSX.Element => {
  return (
    <ActionButton
      type="submit"
      variant={variant}
      color={color}
      onClick={(e) => {
        if (onClick) onClick(e);
        gtagSubmit();
      }}
      {...rest}
    >
      {children}
    </ActionButton>
  );
};
