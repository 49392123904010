import { Box, FormControlLabel, Checkbox, makeStyles } from '@material-ui/core';
import {
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
} from '@material-ui/icons';
import { useForm, Controller } from 'react-hook-form';
import { RadioGroup, TextField } from 'components/form';
import { HelpIconTooltip } from 'components/Tooltip/HelpIconTooltip';
import { crassoneRed, readOnlyTextColor, black } from 'color-palette';
import { CustomerType, CustomerSearchInput } from 'generated/graphql';

type Props = {
  onChange: (values: CustomerSearchInput) => unknown;
};

type FormValues = {
  keyword: string;
  customerType: CustomerType;
  isMainCustomer: boolean;
};

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  textMuted: {
    color: readOnlyTextColor,
  },
  textBlack: {
    color: black,
  },
}));

export const FilterPanel = ({ onChange }: Props): JSX.Element => {
  const classes = useStyles();
  const { control, getValues } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      keyword: '',
      customerType: CustomerType.Corporate,
      isMainCustomer: false,
    },
  });

  const handleChange = () => {
    const { keyword, isMainCustomer, customerType } = getValues();
    onChange({ keyword, isMainCustomer, customerType });
  };

  // keyword検索対象
  // name representative_name company_memo
  // emails_email contacts_phone_number contacts_fax_number

  return (
    <form>
      <Box p={2}>
        <Box className={classes.box}>
          <Box mr={4}>
            <RadioGroup
              name="customerType"
              control={control}
              row
              onChange={handleChange}
              options={[
                { label: '法人', value: CustomerType.Corporate },
                { label: '個人', value: CustomerType.Individual },
              ]}
            />
          </Box>
          <Box mb={3}>
            <FormControlLabel
              label={
                <span
                  className={
                    getValues('isMainCustomer')
                      ? classes.textBlack
                      : classes.textMuted
                  }
                >
                  主要取引先のみ
                </span>
              }
              control={
                <Controller
                  control={control}
                  name="isMainCustomer"
                  render={({ field }) => (
                    <Checkbox
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                        handleChange();
                      }}
                      color="primary"
                      icon={<FavoriteBorderIcon color="disabled" />}
                      checkedIcon={
                        <FavoriteIcon style={{ color: crassoneRed.light }} />
                      }
                    />
                  )}
                />
              }
            />
            <HelpIconTooltip
              tooltipContent={
                <>
                  顧客の中から主要取引先のみを結果に表示します。
                  <br />
                  主要取引先は顧客の詳細画面から設定することができます。
                </>
              }
            />
          </Box>
        </Box>
        <TextField
          name="keyword"
          control={control}
          label="キーワードによる絞り込み"
          hideRequiredMark
          onChange={handleChange}
          tooltipContent="検索対象: 名前、代表者名、社内メモ、メールアドレス、電話番号、FAX番号"
          gutterBottom
        />
      </Box>
    </form>
  );
};
