import { Box, makeStyles } from '@material-ui/core';
import { H2 } from 'components/presentation/headers/';
import { LoadError, LoadingSpinner } from 'components/notification';
import styled from 'styled-components';
import { SummaryOrderBreakdownByCustomerChart } from './SummaryOrderBreakdownByCustomerChart';
import { useSummaryOrderBreakdownByCustomerQuery } from 'generated/graphql';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

const Container = styled.div`
  display: grid;
  grid-template-columns: 60% auto;
`;

const LeftPane = styled.div`
  grid-column-start: 1;
  grid-column-end: 1;
`;
const RightPane = styled.div`
  grid-column-start: 2;
  grid-column-end: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  text-align: left;
`;

export const SummaryOrderBreakdownByCustomer = (): JSX.Element => {
  const classes = useStyles();
  const {
    data,
    loading,
    error: loadError,
  } = useSummaryOrderBreakdownByCustomerQuery({
    context: { clientName: 'company' },
  });

  const result = data?.companySummaryOrderBreakdownByCustomer;
  if (loadError) {
    return (
      <Box className={classes.root}>
        <H2>顧客毎の受注高</H2>
        <LoadError error={loadError} />
      </Box>
    );
  }

  if (result == null || loading) {
    return (
      <Box className={classes.root}>
        <H2>顧客毎の受注高</H2>
        <LoadingSpinner />
      </Box>
    );
  }

  const resultItems =
    result.resultItems.slice().map((v) => {
      return { ...v, orderedAmount: Number(v.orderedAmount) };
    }) || [];

  return (
    <Box className={classes.root}>
      <H2>顧客毎の受注高</H2>
      {resultItems.length ? (
        <Container>
          <LeftPane>
            <SummaryOrderBreakdownByCustomerChart
              periodFrom={result.periodFrom}
              periodTo={result.periodTo}
              data={resultItems}
            />
          </LeftPane>
          <RightPane>
            <div>
              {resultItems
                .slice(0, 5)
                .map(({ name, orderedAmount, contractCount }, index) => (
                  <Box key={index} mb={2}>
                    <div style={{ fontWeight: 'bold' }}>{name}</div>
                    <div style={{ textAlign: 'right' }}>
                      {orderedAmount.toLocaleString()}円
                      <small>（{contractCount}件）</small>
                    </div>
                  </Box>
                ))}
            </div>
          </RightPane>
        </Container>
      ) : (
        <div style={{ height: 300, padding: 50 }}>
          表示できるデータがありません。
        </div>
      )}
    </Box>
  );
};
