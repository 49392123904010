import { ReactChild } from 'react';
import {
  makeStyles,
  TableSortLabel as MuiTableSortLabel,
} from '@material-ui/core';
import { SortOrder } from 'generated/graphql';

const useStyles = makeStyles(() => ({
  activeSortIcon: { opacity: 1 },
  inactiveSortIcon: { opacity: 0.3 },
}));

type Props = {
  active: boolean;
  direction: SortOrder;
  onSort: (direction: SortOrder) => unknown;
  children: ReactChild;
};

export const TableSortLabel = ({
  active,
  direction,
  onSort,
  children,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <MuiTableSortLabel
      active={active}
      direction={direction}
      // activeの時はひっくり返す。そうじゃない時は初回なのでasc固定
      onClick={() => onSort(active ? toggleOrder(direction) : SortOrder.Asc)}
      classes={{
        icon: active ? classes.activeSortIcon : classes.inactiveSortIcon,
      }}
    >
      {children}
    </MuiTableSortLabel>
  );
};

function toggleOrder(value: SortOrder) {
  return value === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
}
