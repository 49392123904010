import { OrderBreakdownResultItem } from 'generated/graphql';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Label,
  ResponsiveContainer,
} from 'recharts';
import { CustomLabel } from './CustomLabel';

import format from 'date-fns/format';
import { Box } from '@material-ui/core';

type Props = {
  periodFrom: string;
  periodTo: string;
  data: (Omit<OrderBreakdownResultItem, 'orderedAmount'> & {
    orderedAmount: number;
  })[];
};

const colors = ['#07f', '#5a3', '#f00', '#0cc', '#ff0', '#aaa'];

const formatter = (value: string | number | (string | number)[]) => {
  return `${value.toLocaleString()}円`;
};

export const SummaryOrderBreakdownByCustomerChart = ({
  periodFrom,
  periodTo,
  data,
}: Props): JSX.Element => {
  const totalOrderedAmount = data
    .map((item) => item.orderedAmount)
    .reduce((prev, curr) => prev + curr, 0);
  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Tooltip
            formatter={formatter}
            labelStyle={{ marginBottom: '2px' }}
            wrapperStyle={{ minWidth: '180px' }}
          />
          <Pie
            data={data}
            dataKey="orderedAmount"
            nameKey="name"
            cx="50%"
            cy="50%"
            startAngle={90}
            endAngle={-270}
            innerRadius="60%"
            outerRadius="75%"
          >
            {data.map((_entry, index) => (
              <Cell
                key={index}
                fill={
                  data.length === index + 1
                    ? '#aaa'
                    : colors[index % colors.length]
                }
              />
            ))}
            <Label
              content={({ viewBox }) => {
                return (
                  <CustomLabel
                    viewBox={viewBox}
                    totalOrderedAmount={totalOrderedAmount}
                  />
                );
              }}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Box component="p" textAlign="center" mt={-2}>{`${format(
        Date.parse(periodFrom),
        'yyyy年MM月',
      )} ~ ${format(Date.parse(periodTo), 'yyyy年MM月')} 顧客毎の受注高`}</Box>
    </>
  );
};
