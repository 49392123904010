import { ReactChild } from 'react';
import {
  Tooltip as MuiToolTip,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { iconOnLightBackground } from 'color-palette';

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      fontSize: 14,
    },
    iconStyle: {
      color: iconOnLightBackground.inactive,
      '&:hover': {
        color: iconOnLightBackground.active,
      },
    },
  }),
);

export type TooltipTextProps = {
  tooltipContent: ReactChild;
};

export const HelpIconTooltip: React.FC<TooltipTextProps> = ({
  tooltipContent,
}: TooltipTextProps) => {
  const classes = useStyles();
  return (
    <MuiToolTip
      title={tooltipContent}
      arrow
      classes={{ tooltip: classes.tooltip }}
    >
      <HelpOutline fontSize="small" className={classes.iconStyle} />
    </MuiToolTip>
  );
};
