import { ApolloError } from '@apollo/client';
import {
  Dashboard_TodoList_TodoFragment as Todo,
  PaginationNav_PageInfoFragment,
  TodoSortItem,
  SortOrder,
  useDashboard_TodoListQuery,
  SearchTodoStatusType,
} from 'generated/graphql';
import { useLoginMember, LoginMember } from 'hooks/useLoginUser';
import { useState } from 'react';

type HookReturnType = {
  loginMember: LoginMember | null;
  pageInfo: PaginationNav_PageInfoFragment | undefined;
  todos: Todo[];
  loading: boolean;
  loadError: ApolloError | undefined;
  openTodoId: string | null;
  handleDialogOpen: (todoId: string) => void;
  handleDialogClose: () => void;
  refetch: () => Promise<unknown>;
};

export const useTodoList = (): HookReturnType => {
  const loginMember = useLoginMember();
  const [openTodoId, setOpenTodoId] = useState<string | null>(null);
  const {
    data,
    loading,
    error: loadError,
    refetch,
  } = useDashboard_TodoListQuery({
    variables: {
      limit: 5,
      searchConditions: {
        assigneeIds: loginMember ? [loginMember.id] : [],
        status: SearchTodoStatusType.OnlyUnfinished,
      },
      sortCondition: {
        itemName: TodoSortItem.ExpiredAt,
        sortOrder: SortOrder.Asc,
      },
    },
    context: {
      clientName: 'company',
    },
  });

  const pageInfo = data?.companyListTodos.pageInfo;
  const todos = data?.companyListTodos.collection || [];

  const handleDialogOpen = (todoId: string) => {
    setOpenTodoId(todoId);
  };

  const handleDialogClose = () => {
    setOpenTodoId(null);
  };

  return {
    loginMember,
    pageInfo,
    todos,
    loading,
    loadError,
    openTodoId,
    handleDialogOpen,
    handleDialogClose,
    refetch,
  };
};
