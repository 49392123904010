import { useProjectPhaseSelectorQuery } from 'generated/graphql';
import { companyContext as context } from 'apolloClient';
import { ChangeEvent, useState, useEffect } from 'react';
import { existenceFilter } from 'utils/type-filter';

type Props = {
  values: string[];
  onChange: (values: string[]) => void;
};

type HookReturnType = {
  loading: boolean;
  loadError: Error | undefined;
  options: { label: string; value: string }[];
  selectedPhases: { label: string; value: string }[];
  handleChange: (
    _event: ChangeEvent<unknown>,
    values: {
      label: string;
      value: string;
    }[],
  ) => void;
};

export const useMultipleProjectPhaseSelector = ({
  values,
  onChange,
}: Props): HookReturnType => {
  const { data, loading, error: loadError } = useProjectPhaseSelectorQuery({
    context,
  });

  const [selectedPhases, setSelectedPhases] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const projectPhases = data?.listProjectPhases;
  const options =
    projectPhases?.map((phase) => ({
      label: phase.name,
      value: phase.id,
    })) ?? [];

  useEffect(() => {
    if (projectPhases) {
      setSelectedPhases(
        values
          .map((id) => projectPhases.find((phase) => phase.id === id))
          .filter(existenceFilter)
          .map((phase) => ({ label: phase.name, value: phase.id })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectPhases, values]);

  const handleChange = (
    _event: ChangeEvent<unknown>,
    values: {
      label: string;
      value: string;
    }[],
  ) => {
    setSelectedPhases(values);
    onChange(values.map((v) => v.value));
  };

  return {
    loading,
    loadError,
    options,
    selectedPhases,
    handleChange,
  };
};
