import { Dialog } from 'components/dialogs/Dialog';
import { LoadError, LoadingSpinner } from 'components/notification';
import { TodoDetailPanel } from './components/TodoDetailPanel';
import { useTodoDialog } from './useTodoDialog';
import { EditTodoForm } from 'page-components/company/todos/EditTodoForm';

type Props = {
  id: string;
  open: boolean;
  onClose: () => unknown;
  onChange: () => unknown;
};

export const TodoDialog = ({
  id,
  open,
  onClose,
  onChange,
}: Props): JSX.Element => {
  const {
    todo,
    loadError,
    loading,
    isEditMode,
    toggleEditMode,
    handleSubmit,
  } = useTodoDialog({ id, onClose, onChange });

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      title={todo?.title ?? ''}
      actionButtons={[
        {
          label: '閉じる',
          color: 'default',
          variant: 'outlined',
          onClick: onClose,
        },
      ]}
    >
      {loadError ? (
        <LoadError error={loadError} />
      ) : loading || todo == null ? (
        <LoadingSpinner />
      ) : isEditMode ? (
        <EditTodoForm id={todo.id} onSubmit={handleSubmit} />
      ) : (
        <TodoDetailPanel
          todo={todo}
          toggleEditMode={toggleEditMode}
          onDeleted={() => {
            onClose();
            onChange();
          }}
        />
      )}
    </Dialog>
  );
};
