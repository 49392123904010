// TODO: Propsに { multiple: boolean } を加えて1つにしたい

import { TextField as MuiTextField } from '@material-ui/core';
import { LoadError } from 'components/notification';
import { useMultipleProjectPhaseSelector } from './useMultipleProjectPhaseSelector';
import { Autocomplete } from '@material-ui/lab';
import { FieldLabel } from 'components/form';
import { Box } from '@material-ui/core/';

type Props = {
  label?: string;
  required?: boolean;
  hideRequiredMark?: boolean;
  gutterBottom?: boolean;
  values: string[];
  onChange: (values: string[]) => void;
};

export const MultipleProjectPhaseSelector = ({
  label,
  required = false,
  hideRequiredMark = false,
  gutterBottom = false,
  values,
  onChange,
}: Props) => {
  const {
    loading,
    loadError,
    options,
    selectedPhases,
    handleChange,
  } = useMultipleProjectPhaseSelector({ values, onChange });

  if (loadError) return <LoadError />;

  return (
    <Box mb={gutterBottom ? 2 : 0}>
      {label && (
        <FieldLabel
          label={label}
          required={required}
          hideRequiredMark={hideRequiredMark}
        />
      )}
      <Autocomplete
        value={selectedPhases}
        getOptionSelected={(
          option: { label: string; value: string },
          value: { label: string; value: string },
        ) => {
          return option.value === value.value;
        }}
        multiple
        disableCloseOnSelect
        loading={loading}
        onChange={handleChange}
        options={options}
        getOptionLabel={(option: { label: string; value: string }) =>
          option.label
        }
        renderInput={(params) => (
          <MuiTextField {...params} variant="outlined" fullWidth size="small" />
        )}
      />
    </Box>
  );
};
