import { useState } from 'react';
import { companyContext as context } from 'apolloClient';
import { useDisplaySnackbar } from 'hooks/useDisplaySnackbar';
import { Severity } from 'providers/SnackbarProvider/Severity';
import {
  useFinishTodoMutation,
  useUnfinishTodoMutation,
  useDeleteTodoMutation,
} from 'generated/graphql';

type Props = {
  id: string;
  onDeleted?: () => void;
};

type HookReturnType = {
  handleFinish: () => void;
  handleUnfinish: () => void;
  handleDelete: () => void;
  toggleHideExceptComments: () => void;
  displayAllTodoActivityLogs: boolean;
};

export const useTodoDetailPanel = ({
  id,
  onDeleted,
}: Props): HookReturnType => {
  const [displayAllTodoActivityLogs, setDisplayAllTodoActivityLogs] = useState(
    true,
  );
  const toggleHideExceptComments = () =>
    setDisplayAllTodoActivityLogs(!displayAllTodoActivityLogs);

  const [finishTodo] = useFinishTodoMutation({
    context,
    refetchQueries: ['TodoDialog'],
  });
  const [unfinishTodo] = useUnfinishTodoMutation({
    context,
    refetchQueries: ['TodoDialog'],
  });
  const [deleteTodo] = useDeleteTodoMutation({
    context,
    refetchQueries: ['ProjectDetail_BasicInfo_SidePane', 'Dashboard_TodoList'],
  });
  const { show } = useDisplaySnackbar();

  const handleFinish = async () => {
    try {
      const { errors } = await finishTodo({ variables: { todoId: id } });
      if (errors) throw new Error(errors[0].message);

      show('このToDoを完了しました', Severity.Success);
    } catch (err: unknown) {
      console.error(err);
      show('エラーが発生しました', Severity.Error);
    }
  };

  const handleUnfinish = async () => {
    try {
      const { errors } = await unfinishTodo({ variables: { todoId: id } });
      if (errors) throw new Error(errors[0].message);

      show('このToDoを未完了に戻しました', Severity.Success);
    } catch (err: unknown) {
      console.error(err);
      show('エラーが発生しました', Severity.Error);
    }
  };
  const handleDelete = async () => {
    try {
      const { errors } = await deleteTodo({ variables: { todoId: id } });
      if (errors) throw new Error(errors[0].message);

      if (onDeleted) onDeleted();

      show('このToDoを削除しました', Severity.Success);
    } catch (err: unknown) {
      console.error(err);
      show('エラーが発生しました', Severity.Error);
    }
  };

  return {
    handleFinish,
    handleUnfinish,
    handleDelete,
    toggleHideExceptComments,
    displayAllTodoActivityLogs,
  };
};
