import { Grid } from '@material-ui/core';
import { SalesSummaryByContractDate } from './components/SalesSummaryByContractDate';
import { SalesSummaryByEndDate } from './components/SalesSummaryByEndDate';
import { useDashboard } from './useDashboard';
import { Container } from 'components/presentation/containers/';
import { DisplayItemCode } from 'generated/graphql';
import { SummaryOrderBreakdownByCustomer } from './components/OrderRatioOnClinetEvery';
import { TodoList } from './components/TodoList';
import { H2 } from 'components/presentation/headers';

const displayComponentsByCode = {
  [DisplayItemCode.SalesSummaryByContractDate]: SalesSummaryByContractDate,
  [DisplayItemCode.SalesSummaryByEndDate]: SalesSummaryByEndDate,
  [DisplayItemCode.SummaryOrderBreakdownByCustomer]: SummaryOrderBreakdownByCustomer,
};

export const Dashboard = (): JSX.Element => {
  const { displayItemCodes } = useDashboard();
  return (
    <Container>
      <Grid container spacing={1} style={{ maxWidth: '100%' }}>
        {displayItemCodes.map((itemCode, index) => {
          const DisplayItem = displayComponentsByCode[itemCode];
          return (
            <Grid key={index} item xs={12} md={6}>
              <DisplayItem />
            </Grid>
          );
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        <H2>担当のToDo</H2>
        <TodoList />
      </Grid>
    </Container>
  );
};
