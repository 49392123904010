import { ApolloError } from '@apollo/client';
import {
  useSalesSummaryByContractDateQuery,
  SalesSummaryByContractDate_SalesSummaryResultFragment,
} from 'generated/graphql';

type HookReturnType = {
  salesSummary: (Omit<
    SalesSummaryByContractDate_SalesSummaryResultFragment,
    'orderedAmount' | 'salesAmount'
  > & { orderedAmount: number; salesAmount: number })[];
  loading: boolean;
  loadError: ApolloError | undefined;
};

export const useSalesSummaryByContractDate = (): HookReturnType => {
  const {
    data,
    loading,
    error: loadError,
  } = useSalesSummaryByContractDateQuery({
    context: { clientName: 'company' },
  });

  const salesSummary =
    data?.companySalesSummaryByContractDate.map((v) => {
      return {
        ...v,
        salesAmount: Number(v.salesAmount),
        orderedAmount: Number(v.orderedAmount),
      };
    }) || [];

  return {
    salesSummary,
    loading,
    loadError,
  };
};
