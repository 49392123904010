import { useState } from 'react';
import { Button, ButtonProps } from 'components/buttons';
import { CustomerSelectDialog, Customer } from './CustomerSelectDialog';

type Props = {
  // 検討: 基本的にはidだけを返すのがスマートだけど、選択されたやつの名前を見たい、みたいなのはすぐありそう
  // 呼び出し側でidからすぐに参照できそうになかったらオブジェクトごと返してしまった方がいいかも
  onSelect(customer: Customer): unknown;
  disabled?: boolean;
  includesContactInfo?: boolean; // 住所その他の連絡先情報も取得する
  label?: string;
  size?: ButtonProps['size'];
};

export const CustomerSelectDialogButton = ({
  onSelect,
  disabled = false,
  includesContactInfo = false,
  label = '顧客を選択',
  size,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleSelect = (customer: Customer) => {
    setOpen(false);
    onSelect(customer);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <CustomerSelectDialog
        open={open}
        onClose={handleClose}
        onSelect={handleSelect}
        includesContactInfo={includesContactInfo}
      />
      <Button
        color="default"
        variant="outlined"
        onClick={handleOpen}
        disabled={disabled}
        size={size}
      >
        {label}
      </Button>
    </>
  );
};
