import { useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { Dialog, ActionButton } from 'components/dialogs/Dialog';
import { NewCustomerForm } from './NewCustomerForm';
import { CustomerList } from './CustomerList';
import { CustomerSelectDialog_CustomerFragment } from 'generated/graphql';

export type Customer = CustomerSelectDialog_CustomerFragment;

type Props = {
  open: boolean;
  onClose: () => unknown;
  onSelect(customer: Customer): unknown;
  includesContactInfo?: boolean; // 住所その他の連絡先情報も取得する
};

export const CustomerSelectDialog = ({
  open,
  onClose,
  onSelect,
  includesContactInfo = false,
}: Props): JSX.Element => {
  const [tabValue, setTabValue] = useState(0);

  const actionButtons: ActionButton[] = [
    {
      label: 'キャンセル',
      onClick: () => {
        onClose();
      },
      color: 'default',
      variant: 'outlined',
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      title="顧客を選択"
      actionButtons={actionButtons}
    >
      <Tabs
        value={tabValue}
        onChange={(_e, v) => setTabValue(v)}
        indicatorColor="primary"
      >
        <Tab label="既存の顧客から選択" {...a11yProps(0)} />
        <Tab label="新しい顧客を登録" {...a11yProps(1)} />
      </Tabs>
      <>
        {tabValue === 0 && (
          <CustomerList
            onSelect={onSelect}
            includesContactInfo={includesContactInfo}
          />
        )}
        {tabValue === 1 && (
          <NewCustomerForm
            onCreate={onSelect}
            submitButtonLabel="新しい顧客を登録する"
            includesContactInfo={includesContactInfo}
          />
        )}
      </>
    </Dialog>
  );
};

function a11yProps(index: number) {
  return {
    id: `customer-dialog-tab-${index}`,
    'aria-controls': `customer-dialog-tabpanel-${index}`,
  };
}
