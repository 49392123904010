import { LoadError, LoadingSpinner } from 'components/notification';
import { TodoForm } from '../TodoForm/TodoForm';
import { useEditTodoForm } from './useEditTodoForm';

type Props = {
  id: string;
  // TODO更新後に何かハンドリングしたいときに使う
  onSubmit?: (id: string) => void;
};

export const EditTodoForm = ({ id, onSubmit }: Props): JSX.Element => {
  const { loading, loadError, defaultValues, handleSubmit } = useEditTodoForm({
    id,
    onSubmit,
  });

  if (loadError) return <LoadError error={loadError} />;
  if (loading) return <LoadingSpinner />;

  return (
    <TodoForm
      mode="edit"
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
    />
  );
};
