import { SecuredPage } from 'components/SecuredPage';
import { Dashboard } from 'page-components/company/dashboard/Dashboard';

const Home = (): JSX.Element => {
  return (
    <SecuredPage appMode="company" title="ダッシュボード">
      <Dashboard />
    </SecuredPage>
  );
};

export default Home;
