import { Box, FormControl } from '@material-ui/core';
import {
  RegisterOptions,
  FieldValues,
  FieldPath,
  PathValue,
} from 'react-hook-form';
import { BaseRadioGroup, BaseRadioGroupProps } from './BaseRadioGroup';
import { FieldLabel } from '../FieldLabel';

export type BooleanRadioGroupProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Pick<
  BaseRadioGroupProps<TFieldValues, TName>,
  'control' | 'name' | 'onChange'
> & {
  label?: string;
  trueLabel?: string;
  falseLabel?: string;
  defaultValue?: boolean;
  required?: boolean;
  row?: boolean;
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  gutterBottom?: boolean;
  disabled?: boolean;
  hideRequiredMark?: boolean;
};

export const BooleanRadioGroup = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  control,
  name,
  label,
  trueLabel = 'Yes',
  falseLabel = 'No',
  defaultValue,
  row = false,
  required = false,
  gutterBottom,
  disabled = false,
  onChange,
  hideRequiredMark = false,
}: BooleanRadioGroupProps<TFieldValues, TName>): JSX.Element => {
  const rules = required
    ? {
        validate: (value: boolean | undefined) => value != null,
      }
    : undefined;

  const options = [
    { label: trueLabel, value: 'true' },
    { label: falseLabel, value: 'false' },
  ];

  return (
    <Box width="100%" mb={gutterBottom ? 2 : 0}>
      <FormControl component="fieldset">
        {label && (
          <FieldLabel
            label={label}
            required={required}
            hideRequiredMark={hideRequiredMark}
          />
        )}
        <BaseRadioGroup
          name={name}
          control={control}
          convertFromString={
            convertFromString as (v: string) => PathValue<TFieldValues, TName>
          }
          convertToString={convertToString}
          defaultValue={
            defaultValue == null
              ? undefined
              : (defaultValue as PathValue<TFieldValues, TName>)
          }
          options={options}
          row={row}
          rules={rules}
          disabled={disabled}
          onChange={onChange}
        />
      </FormControl>
    </Box>
  );
};

const convertToString = (v: boolean | null | undefined): string => {
  return v == null ? '' : v ? 'true' : 'false';
};

const convertFromString = (v: string): boolean | null => {
  return v === '' ? null : v === 'true';
};
