import { Ref } from 'react';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import {
  useController,
  Control,
  RegisterOptions,
  FieldValues,
  FieldPath,
  PathValue,
} from 'react-hook-form';
import { formatDate, FormatDateOption } from 'utils/date';

export type BaseDateFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  control: Control<TFieldValues>;
  name: TName;
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  containerRef?: Ref<HTMLDivElement> | undefined;
} & Omit<DatePickerProps, 'value' | 'defaultValue' | 'onChange'> & {
    onChange?: (value: PathValue<TFieldValues, TName>) => unknown;
    // defaultValueはinputではなくControllerに渡すもの（Controlled inputなのでinput.defaultValueは使わない）
    // field arrayを利用する時には必須
    defaultValue?: PathValue<TFieldValues, TName>;
  };

export const BaseDateField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  control,
  name,
  rules,
  containerRef,
  onChange,
  defaultValue = undefined,
  ...rest
}: BaseDateFieldProps<TFieldValues, TName>): JSX.Element => {
  const { field } = useController({ name, control, rules, defaultValue });

  const handleChange = (date: Date | null) => {
    field.onChange(date);
    if (onChange != null) {
      onChange(date as PathValue<TFieldValues, TName>);
    }
  };

  return (
    <DatePicker
      labelFunc={dateLabelFunc}
      {...rest}
      {...field}
      value={(field.value ?? null) as Date | null}
      onChange={handleChange}
      ref={containerRef}
    />
  );
};

const dateLabelFunc = (date: Date | null, invalidLabel: string) =>
  invalidLabel || formatDate(date, FormatDateOption.full);
