import {
  AttributesTableItem,
  AttributesTableItemData,
} from './AttributesTableItem';

export type AttributesTableProps = {
  data: AttributesTableItemData[];
  labelWidth?: number;
};

export const AttributesTable = ({
  data,
  labelWidth = 100,
}: AttributesTableProps): JSX.Element => {
  // このコンポーネントの責務は（今の所）ラベルの幅を揃えるだけ
  return (
    <>
      {data.map((item, index) => (
        <AttributesTableItem key={index} labelWidth={labelWidth} {...item} />
      ))}
    </>
  );
};
