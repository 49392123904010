import {
  Control,
  FieldValues,
  FieldPath,
  PathValue,
  Controller,
  RegisterOptions,
} from 'react-hook-form';

export type HiddenFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  control: Control<TFieldValues>;
  name: TName;
  defaultValue?: PathValue<TFieldValues, TName>;
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  /** rulesを指定している場合はそちらが優先される */
  required?: boolean;
};

export const HiddenField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  control,
  name,
  defaultValue,
  rules,
  required,
}: HiddenFieldProps<TFieldValues, TName>): JSX.Element => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => <input type="hidden" {...field} />}
      rules={rules ?? { required }}
    />
  );
};
