import { Box } from '@material-ui/core';
import { DateTimeField, SubmitButton, TextField } from 'components/form';
import { ProcessError } from 'components/notification';
import { DefaultValues, FormProvider } from 'react-hook-form';
import { TodoFormValues } from './TodoFormValues';
import { useTodoForm } from './useTodoForm';
import { MemberSelector } from 'components/MemberSelector';
import { ProjectPhaseSelector } from 'components/ProjectPhaseSelector';

type Props = {
  mode: 'new' | 'edit';
  defaultValues: DefaultValues<TodoFormValues>;
  onSubmit: (
    formValues: TodoFormValues,
    projectPhaseId: string | null,
  ) => unknown;
};

export const TodoForm = ({ mode, defaultValues, onSubmit }: Props) => {
  const {
    formMethods,
    processing,
    processError,
    isFormValid,
    selectedMemberIds,
    selectedProjectPhaseId,
    handleAssigneeChange,
    handleProjectPhaseChange,
    handleSubmit,
  } = useTodoForm({
    defaultValues,
    onSubmit,
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <TextField
          control={formMethods.control}
          name="title"
          label="タイトル"
          rules={{ required: true }}
          gutterBottom
          placeholder="タイトル"
        />
        <ProjectPhaseSelector
          label="フェーズ"
          value={selectedProjectPhaseId}
          onChange={handleProjectPhaseChange}
          gutterBottom
        />
        <TextField
          control={formMethods.control}
          name="description"
          label="説明"
          placeholder="説明"
          rules={{ required: false }}
          gutterBottom
          multiline
        />
        <MemberSelector
          label="担当者"
          gutterBottom
          values={selectedMemberIds}
          onChange={handleAssigneeChange}
        />
        <DateTimeField
          control={formMethods.control}
          name="expiredAt"
          label="期限"
          gutterBottom
        />
        {processError && (
          <ProcessError error={processError}>
            エラーが発生しました。
          </ProcessError>
        )}
        <Box textAlign="center" mt={6}>
          <SubmitButton disabled={!isFormValid} processing={processing}>
            {mode === 'new' ? '登録する' : '更新する'}
          </SubmitButton>
        </Box>
      </form>
    </FormProvider>
  );
};
