import { ReactChild, ReactNode, Ref } from 'react';
import {
  Control,
  RegisterOptions,
  FieldValues,
  FieldPath,
  PathValue,
} from 'react-hook-form';

type Rules = Exclude<
  RegisterOptions,
  'valueAsNumber' | 'valueAsDate' | 'setValueAs'
>;

/**
 * 全ての入力フィールドが共通して持っているProps
 */
export type CommonFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  control: Control<TFieldValues>;
  name: TName;
  // defaultValue必要？ fieldごとに渡す必要はない気がする
  // defaultValue?: PathValue<TFieldValues, TName>;
  label: string;
  /** 必須・任意マークを隠す */
  hideRequiredMark?: boolean;
  /** rulesがrequireだけの場合が多いので、rulesの外に出して簡便に指定できるようにする */
  required?: boolean;
  /** react-hook-formのrulesに渡す値 */
  rules?: Rules;
  /** レイアウトを楽にするために、下にマージンを持たせる */
  gutterBottom?: boolean;
  /** 編集不可能な状態にする */
  disabled?: boolean;
  /** エラー状態を示す。ヘルパーテキストが赤くなる */
  error?: boolean;
  /** 主にバリデーションエラーを表すのに使う */
  helperText?: ReactNode;
  /** Tooltipに表示させるtext */
  tooltipContent?: ReactChild;
  /** 一番外側のdivに対するref (inputに対するrefはinputRefを使う) */
  containerRef?: Ref<HTMLDivElement> | undefined; // もはや使われていない気がするので消した方がよさそう
  /** どちらかというと値を使いたいというよりvalidationをtriggerしたい時などに使う */
  onChange?: (value: PathValue<TFieldValues, TName>) => unknown;
};

/**
 * CommonFieldPropsのrequiredとrulesを合成して一つのrulesオブジェクトにまとめる
 * @param required
 * @param rules
 * @returns requiredの値が組み込まれたrulesオブジェクト
 */
export function mergeRequiredIntoRules(
  required: boolean | undefined,
  rules: Rules | undefined,
): Rules | undefined {
  if (!required && !rules) return undefined;

  return {
    required,
    ...rules,
  };
}
