import { LoadError, LoadingSpinner } from 'components/notification';
import { useTodoList } from './useTodoList';
import { Box, List, Typography } from '@material-ui/core';
import { Todo } from 'components/todos';
import { Button } from 'components/buttons';
import { pagesPath } from 'utils/$path';

export const TodoList = () => {
  const {
    loginMember,
    loadError,
    pageInfo,
    todos,
    openTodoId,
    handleDialogOpen,
    handleDialogClose,
    refetch,
  } = useTodoList();

  if (loadError) return <LoadError error={loadError} />;

  return (
    <>
      {pageInfo == null || todos == null ? (
        <LoadingSpinner />
      ) : todos.length ? (
        <>
          <List>
            {todos.map((todo) => (
              <Todo
                key={todo.id}
                todo={todo}
                onChange={() => refetch()}
                dialogOpen={openTodoId === todo.id}
                onDialogOpen={handleDialogOpen}
                onDialogClose={handleDialogClose}
              />
            ))}
          </List>
          {5 < pageInfo.totalCount && (
            <Box textAlign="center">
              <Button
                href={pagesPath.todos.$url({
                  query: { assigneeIds: loginMember ? [loginMember.id] : [] },
                })}
                variant="outlined"
                color="default"
              >
                もっと見る
              </Button>
            </Box>
          )}
        </>
      ) : (
        <Typography>自身が担当するToDoがありません。</Typography>
      )}
    </>
  );
};
