import { isValidElement, ReactChild, MouseEventHandler } from 'react';
import Link, { LinkProps } from 'next/link';
import {
  TableCell,
  TableRow as MuiTableRow,
  TableRowProps as MuiTableRowProps,
  TableCellProps,
  makeStyles,
} from '@material-ui/core';
import { isReactText } from './isReactText';

const useStyles = makeStyles(() => ({
  linkStyle: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export type ListTableCellItem =
  | ReactChild
  | {
      content: ReactChild;
      cellProps?: TableCellProps;
    };

export type ListTableRowData =
  | ListTableCellItem[]
  | ({
      cells: ListTableCellItem[];
      link?: LinkProps['href'];
      linkStyle?: boolean; // linkではなくてもマウスポインタを'pointer'にする抜け道
    } & Pick<MuiTableRowProps, 'hover' | 'onClick'>);

type Props = {
  row: ListTableRowData;
};

export const ListTableRow = ({ row }: Props): JSX.Element => {
  const classes = useStyles();

  let cells: ListTableCellItem[];
  let link: LinkProps['href'] | undefined;
  let linkStyle: boolean | undefined;
  let hover = false;
  let onClick: MouseEventHandler<HTMLTableRowElement> | undefined;

  if (Array.isArray(row)) {
    cells = row;
  } else {
    cells = row.cells;
    link = row.link;
    linkStyle = row.linkStyle;
    hover = row.hover ?? false;
    onClick = row.onClick;
  }

  if (link) {
    if (linkStyle === undefined) linkStyle = true;

    return (
      <Link href={link} passHref>
        <MuiTableRow
          component="a"
          hover={hover}
          className={linkStyle ? classes.linkStyle : undefined}
        >
          {renderCells(cells)}
        </MuiTableRow>
      </Link>
    );
  }

  return (
    <MuiTableRow
      component="div"
      hover={hover}
      className={linkStyle ? classes.linkStyle : undefined}
      onClick={onClick}
    >
      {renderCells(cells)}
    </MuiTableRow>
  );
};

const renderCells = (cells: ListTableCellItem[]): JSX.Element[] => {
  return cells.map((item, index) => {
    if (isReactText(item) || isValidElement(item)) {
      item = { content: item };
    }

    return (
      <TableCell key={index} {...item.cellProps} component="div">
        {item.content}
      </TableCell>
    );
  });
};
