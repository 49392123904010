import { useState } from 'react';
import { useForm, Control } from 'react-hook-form';
import { useAddTodoCommentMutation } from 'generated/graphql';
import { companyContext as context } from 'apolloClient';
import { wrapUnknownError } from 'utils/wrapUnknownError';

type FormValues = {
  comment: string;
};

type Props = {
  todoId: string;
};

export const useCommentForm = ({
  todoId,
}: Props): {
  handleSubmit: () => unknown;
  control: Control<FormValues>;
  isValid: boolean;
  processing: boolean;
  processError: Error | undefined;
} => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<FormValues>({ mode: 'onChange' });
  const [processing, setProcessing] = useState(false);
  const [processError, setProcessError] = useState<Error>();
  const [addTodoComment] = useAddTodoCommentMutation({
    context,
    refetchQueries: ['TodoDialog'],
  });

  const onSubmit = async ({ comment }: FormValues) => {
    try {
      setProcessing(true);
      await addTodoComment({
        variables: {
          todoId,
          commentText: comment,
        },
      });
      reset();
    } catch (e) {
      setProcessError(wrapUnknownError(e));
    } finally {
      setProcessing(false);
    }
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    control,
    isValid,
    processing,
    processError,
  };
};
