import { FieldValues, FieldPath, PathValue } from 'react-hook-form';
import {
  BaseMuiTextField,
  BaseMuiTextFieldProps,
} from '../common/BaseMuiTextField';

export type BaseTextFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Omit<
  BaseMuiTextFieldProps<TFieldValues, TName>,
  'convertToInputValue' | 'convertFromInputValue'
>;

export const BaseTextField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  ...props
}: BaseTextFieldProps<TFieldValues, TName>): JSX.Element => {
  return (
    <BaseMuiTextField
      {...props}
      convertToInputValue={forceToString}
      convertFromInputValue={(v) => v as PathValue<TFieldValues, TName>}
    />
  );
};

// controlled <-> uncontrolled の変換が起こらないようにする
const forceToString = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  v?: PathValue<TFieldValues, TName>,
): string => {
  return v != null ? `${v}` : '';
};
