import { Box } from '@material-ui/core';
import { ProcessError } from 'components/notification';
import { TextField, RadioGroup, SubmitButton } from 'components/form';
import {
  CustomerSelectDialog_CustomerFragment,
  CustomerType,
} from 'generated/graphql';
import { useNewCustomerForm } from './useNewCustomerForm';

type Props = {
  onCreate: (customer: CustomerSelectDialog_CustomerFragment) => unknown;
  submitButtonLabel?: string;
  includesContactInfo?: boolean;
};

export const NewCustomerForm = ({
  onCreate,
  submitButtonLabel = '保存する',
  includesContactInfo = false,
}: Props): JSX.Element => {
  const {
    control,
    handleSubmit,
    customerType,
    processing,
    processError,
    isValid,
  } = useNewCustomerForm({
    onCreate,
    includesContactInfo,
  });

  return (
    <Box p={2}>
      <form onSubmit={handleSubmit}>
        <TextField
          placeholder="例：〇〇株式会社"
          control={control}
          label="顧客名"
          name="name"
          rules={{ required: true }}
          gutterBottom
        />
        <RadioGroup
          control={control}
          name="customerType"
          label="顧客区分"
          options={[
            { label: '法人', value: 'corporate' },
            { label: '個人', value: 'individual' },
          ]}
          rules={{ required: true }}
          row
          gutterBottom
        />
        {customerType === CustomerType.Corporate && (
          <Box width={400}>
            <TextField
              control={control}
              placeholder="例：山田太郎"
              label="代表者名"
              name="representativeName"
              rules={{ required: false }}
              gutterBottom
            />
          </Box>
        )}
        {processError && <ProcessError error={processError} />}
        <Box my={2} textAlign="center">
          <SubmitButton processing={processing} disabled={!isValid}>
            {submitButtonLabel}
          </SubmitButton>
        </Box>
      </form>
    </Box>
  );
};
