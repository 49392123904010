import { FieldValues, FieldPath, PathValue } from 'react-hook-form';
import {
  BaseMuiTextField,
  BaseMuiTextFieldProps,
} from '../common/BaseMuiTextField';
import { MenuItem } from '@material-ui/core';

export type SelectOption = {
  label: string;
  value: string | undefined;
};

export type BaseSelectFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = Omit<
  BaseMuiTextFieldProps<TFieldValues, TName>,
  'convertToInputValue' | 'convertFromInputValue'
> & {
  options: SelectOption[];
};

export const BaseSelectField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  options,
  ...props
}: BaseSelectFieldProps<TFieldValues, TName>): JSX.Element => {
  return (
    <BaseMuiTextField
      select
      {...props}
      convertToInputValue={forceToString}
      convertFromInputValue={(v) => v as PathValue<TFieldValues, TName>}
    >
      {options.map(({ label, value }, index) => (
        <MenuItem key={index} value={value}>
          {label}
        </MenuItem>
      ))}
    </BaseMuiTextField>
  );
};

// controlled <-> uncontrolled の変換が起こらないようにする
const forceToString = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  v?: PathValue<TFieldValues, TName>,
): string => {
  return v != null ? `${v}` : '';
};
