import { Box } from '@material-ui/core';
import { CustomerSelectDialog_CustomerFragment } from 'generated/graphql';
import { FilterPanel } from './FilterPanel';
import { CustomerListTable } from './CustomerListTable';
import { useCustomerList } from './useCustomerList';
import { LoadError, LoadingSpinner } from 'components/notification';

type Props = {
  onSelect: (customer: CustomerSelectDialog_CustomerFragment) => unknown;
  includesContactInfo?: boolean;
};

export const CustomerList = ({
  onSelect,
  includesContactInfo = false,
}: Props): JSX.Element => {
  const {
    pageInfo,
    handlePageChange,
    customers,
    handleFilterChange,
    handleCustomerSelect,
    loading,
    loadError,
  } = useCustomerList({ onSelect, includesContactInfo });

  if (loadError) return <LoadError error={loadError} />;

  return (
    <Box py={2}>
      <FilterPanel onChange={handleFilterChange} />
      {loading || pageInfo == null ? (
        <LoadingSpinner />
      ) : (
        <CustomerListTable
          customers={customers}
          onSelect={handleCustomerSelect}
          pageInfo={pageInfo}
          onPageChange={handlePageChange}
        />
      )}
    </Box>
  );
};
