import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { Control, Controller, Path } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 120,
      marginBottom: theme.spacing(2),
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.common.white,
      },
    },
  }),
);

export type CheckboxFieldProps<T> = {
  control: Control<T>;
  name: Path<T>;
  label: string;
  required?: boolean;
  helperText?: string;
};

export const CheckboxField = <T extends Record<string, unknown>>({
  control,
  name,
  label,
  required = false,
  helperText = '',
}: CheckboxFieldProps<T>): JSX.Element => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormControlLabel
        control={
          <Controller
            name={name}
            rules={{ required: required }}
            control={control}
            render={({ field: { value, ...field } }) => (
              <Checkbox {...field} checked={!!value} />
            )}
          />
        }
        label={label}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
