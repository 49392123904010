import { Box, makeStyles } from '@material-ui/core';
import { H2 } from 'components/presentation/headers/';
import { HelpIconTooltip } from 'components/Tooltip/HelpIconTooltip';
import { useSalesSummaryByEndDateQuery } from 'generated/graphql';
import { LoadError, LoadingSpinner } from 'components/notification';
import { SalesSummaryChart } from 'components/SalesSummaryChart';

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: theme.spacing(4),
  },
}));

export const SalesSummaryByEndDate = (): JSX.Element => {
  const classes = useStyles();
  const { data, error: loadError, loading } = useSalesSummaryByEndDateQuery({
    context: { clientName: 'company' },
  });
  const salesSummaryResult =
    data?.companySalesSummaryByEndDate.map((v) => {
      return {
        ...v,
        salesAmount: Number(v.salesAmount),
        orderedAmount: Number(v.orderedAmount),
      };
    }) || [];

  return (
    <>
      <H2>
        月別の実績
        <HelpIconTooltip
          tooltipContent={
            <>
              月ごとの売上高・受注高・受注数
              <br />※ 売上高 : 完工日を基準にした請負金額(税抜)の合計
            </>
          }
        />
      </H2>
      {loadError ? (
        <LoadError error={loadError} />
      ) : salesSummaryResult == undefined || loading ? (
        <LoadingSpinner />
      ) : salesSummaryResult?.length ? (
        <Box className={classes.box}>
          <SalesSummaryChart salesSummary={salesSummaryResult} />
        </Box>
      ) : (
        <div style={{ height: 300, padding: 50 }}>
          表示できるデータがありません。
        </div>
      )}
    </>
  );
};
