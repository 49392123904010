// MUIのTextFieldをReact Hook Formで使うためにwrapしたもの。
// それ以上の機能追加や制限を加えたりはしない状態。
// NumberFieldやSelectFieldなど、MUIのTextFieldから派生するものはこれをベースにする。

// TODO: NumberFieldは要件が違う（変わった）ので使わなくなった。Selectも可能であれば独立させてこのコンポーネントは削除する。

import {
  useState,
  useEffect,
  Ref,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
} from 'react';
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core';
import {
  useController,
  Control,
  RegisterOptions,
  FieldValues,
  FieldPath,
  PathValue,
} from 'react-hook-form';

export type BaseMuiTextFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  control: Control<TFieldValues>;
  name: TName;
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  convertToInputValue: (value?: PathValue<TFieldValues, TName>) => string;
  convertFromInputValue: (value: string) => PathValue<TFieldValues, TName>;
  containerRef?: Ref<HTMLDivElement> | undefined;
} & Omit<MuiTextFieldProps, 'value' | 'defaultValue' | 'onChange'> & {
    onChange?: (value: PathValue<TFieldValues, TName>) => unknown;
    // defaultValueはinputではなくControllerに渡すもの（Controlled inputなのでinput.defaultValueは使わない）
    // field arrayを利用する時には必須
    defaultValue?: PathValue<TFieldValues, TName>;
  };

export const BaseMuiTextField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  control,
  name,
  rules,
  convertToInputValue,
  convertFromInputValue,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  containerRef,
  onChange,
  onKeyDown,
  onKeyUp,
  defaultValue = undefined,
  ...rest
}: BaseMuiTextFieldProps<TFieldValues, TName>): JSX.Element => {
  const { field } = useController({ name, control, rules, defaultValue });
  const [displayValue, setDisplayValue] = useState(
    (field.value as string) ?? '',
  );
  const [suspending, setSuspending] = useState(false);

  useEffect(() => {
    // formのreset()などで外部から書き換えられた場合に対応
    setDisplayValue(
      convertToInputValue(field.value as PathValue<TFieldValues, TName>),
    );
    // eslint-disable-next-line
  }, [field.value]);

  const triggerChange = (value: PathValue<TFieldValues, TName>) => {
    field.onChange(value);
    if (onChange != null) {
      onChange(value);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDisplayValue(e.target.value);

    if (!suspending) {
      const convertedValue = convertFromInputValue(e.target.value);
      triggerChange(convertedValue);
      setDisplayValue(convertToInputValue(convertedValue));
    }
  };

  const handleCompositionStart = () => setSuspending(true);
  const handleCompositionEnd = () => {
    setSuspending(false);
    // Windows特定環境でblur時に最初の１桁が重複する問題のワークアラウンド
    // 全角入力確定時の即時フォーマット（カンマ区切り）を諦める
    // const convertedValue = convertFromInputValue(displayValue);
    const convertedValue = displayValue as PathValue<TFieldValues, TName>;
    triggerChange(convertedValue);
    setDisplayValue(convertToInputValue(convertedValue));
  };
  const handleBlur = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const trimmedValue = convertFromInputValue(
      event.target.value.trim(),
    ) as PathValue<TFieldValues, TName>;

    if (field.value !== trimmedValue) {
      triggerChange(trimmedValue);
      setDisplayValue(convertToInputValue(trimmedValue));
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // IME変換中はイベントを送出しない
    // このレベルで全て堰き止めていいかは微妙だが、変換未確定時に何かしたいということはとりあえずなさそう
    if (!suspending && onKeyDown) {
      onKeyDown(e);
    }
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!suspending && onKeyUp) {
      onKeyUp(e);
    }
  };

  return (
    <MuiTextField
      {...rest}
      {...field}
      value={displayValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onCompositionStart={handleCompositionStart}
      onCompositionEnd={handleCompositionEnd}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      // これがあるとselectの時の初回validationが起動しない
      // 現在のところ、これに依存しているコードはないはずなのでコメントアウトする
      // ToDo: 詳しい調査はこれから
      // ref={containerRef}
    />
  );
};
