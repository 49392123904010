import { Box } from '@material-ui/core';
import { SubmitButton, TextField } from 'components/form';
import { useCommentForm } from './useCommentForm';
import { ProcessError } from 'components/notification';

type Props = {
  todoId: string;
};

export const CommentForm = ({ todoId }: Props): JSX.Element => {
  const {
    handleSubmit,
    control,
    isValid,
    processing,
    processError,
  } = useCommentForm({
    todoId,
  });

  return (
    <Box my={2}>
      <form onSubmit={handleSubmit}>
        <TextField
          control={control}
          name="comment"
          required
          label=""
          multiline
          placeholder="コメントを入力してください"
          minRows={2}
        />
        {processError && <ProcessError error={processError} />}
        <Box mt={1}>
          <SubmitButton disabled={!isValid} processing={processing}>
            保存
          </SubmitButton>
        </Box>
      </form>
    </Box>
  );
};
