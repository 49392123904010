import { useState, ReactChild, useEffect } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import {
  useController,
  FieldValues,
  FieldPath,
  PathValue,
  Control,
} from 'react-hook-form';
import { FieldLabel } from 'components/form/FieldLabel';
import { CustomerSelectDialogButton } from './CustomerSelectDialogButton';
import type { Customer } from './CustomerSelectDialog';
import { readOnlyBackgroundColor } from 'color-palette';
import {
  useCustomerIdFieldLazyQuery,
  CustomerIdField_CustomerFragment,
} from 'generated/graphql';

export type CustomerIdFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = {
  existingCustomerName?: string; // 既存顧客名を参照する場合
  control: Control<TFieldValues>;
  name: TName;
  defaultValue?: PathValue<TFieldValues, TName>;
  label?: string;
  required?: boolean;
  gutterBottom?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  helperText?: string;
  tooltipContent?: ReactChild;
  hideRequiredMark?: boolean;
  onChange?: (value: Customer | null) => unknown;
  includesContactInfo?: boolean; // onChangeに渡される値で住所その他の連絡先情報も使用できるようにする
};

/**
 * react-hook-formを使用するフォームに組み込んで使える顧客選択フィールド
 */
export const CustomerIdField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  existingCustomerName,
  control,
  name,
  defaultValue,
  label = '顧客',
  required = false, // 解除ボタンを提供しない
  gutterBottom = false,
  disabled = false, // ボタンは表示しつつ操作できない
  readOnly = false, // 変更するボタンを表示しない
  error = false,
  helperText = '',
  tooltipContent = '',
  hideRequiredMark = false,
  onChange,
  includesContactInfo = false,
}: CustomerIdFieldProps<TFieldValues, TName>): JSX.Element => {
  const [
    selectedCustomer,
    setSelectedCustomer,
  ] = useState<CustomerIdField_CustomerFragment | null>(null);
  const { field } = useController({
    name,
    control,
    defaultValue,
    rules: { required },
  });

  const [query, { data }] = useCustomerIdFieldLazyQuery();

  useEffect(() => {
    // 既に選択されていればqueryを発行
    if (field.value) {
      query({
        variables: { id: field.value },
        context: { clientName: 'company' },
      });
    }
    // eslint-disable-next-line
  }, [field.value]);

  useEffect(() => {
    if (data) {
      setSelectedCustomer(data.companyGetCustomer);
    }
  }, [data]);

  const handleCustomerSelect = (customer: Customer) => {
    setSelectedCustomer(customer);
    field.onChange(customer.id);
    if (onChange != null) {
      onChange(customer);
    }
  };

  const handleUnsetCustomer = () => {
    setSelectedCustomer(null);
    field.onChange(null);
    if (onChange != null) {
      onChange(null);
    }
  };

  return (
    <Box width="100%" mb={gutterBottom ? 2 : 0}>
      <FieldLabel
        label={label}
        required={required}
        tooltipContent={tooltipContent}
        hideRequiredMark={hideRequiredMark}
      />
      <Box display="flex" flexWrap="wrap" alignItems="flex-end">
        <Box mr={2}>
          <TextField
            name="valuename"
            variant="outlined"
            size="small"
            error={error}
            helperText={helperText}
            InputProps={{
              style: {
                minWidth: 280,
                backgroundColor: readOnlyBackgroundColor,
              },
            }}
            inputProps={{ readOnly: true }}
            value={
              existingCustomerName
                ? selectedCustomer?.name ?? existingCustomerName
                : selectedCustomer?.name ?? '選択されていません'
            }
          />
        </Box>
        {!readOnly && (
          <>
            <Box mt={1}>
              <CustomerSelectDialogButton
                onSelect={handleCustomerSelect}
                disabled={disabled}
                includesContactInfo={includesContactInfo}
              />
            </Box>
            {!required && (
              <Box ml={2}>
                <Button
                  variant="text"
                  disabled={disabled}
                  onClick={handleUnsetCustomer}
                >
                  設定を解除
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
