import { useState, useEffect } from 'react';
import { ChangeEvent } from 'react';
import { Box, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import {
  useController,
  FieldValues,
  FieldPath,
  PathValue,
} from 'react-hook-form';
import { formatDate, FormatDateOption } from 'utils/date';
import {
  getHours,
  setHours,
  getMinutes,
  setMinutes,
  startOfDay,
} from 'date-fns';
import { CommonFieldProps } from '../common/CommonFieldProps';
import { grey } from '@material-ui/core/colors';
import { FieldLabel } from '../FieldLabel';

export type DateTimeFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> =
  // ひとまず複雑なrulesは設定させないことにする（将来的に日時レンジで制限したいとかは出てきそう）
  Omit<CommonFieldProps<TFieldValues, TName>, 'rules'> & {
    defaultValue?: PathValue<TFieldValues, TName>;
  };

export const DateTimeField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  control,
  name,
  label,
  hideRequiredMark,
  required = false,
  gutterBottom,
  disabled,
  error,
  helperText,
  tooltipContent,
  onChange,
  defaultValue = undefined,
}: DateTimeFieldProps<TFieldValues, TName>): JSX.Element => {
  const { field } = useController({
    name,
    control,
    rules: { required },
    defaultValue,
  });
  const [dateValue, setDateValue] = useState<Date | null>(
    defaultValue
      ? startOfDay(defaultValue)
      : field.value
      ? startOfDay(field.value)
      : null,
  );
  const [hoursValue, setHoursValue] = useState<number | null>(
    defaultValue
      ? getHours(defaultValue)
      : field.value
      ? getHours(field.value)
      : null,
  );
  const [minutesValue, setMinutesValue] = useState<number | null>(
    defaultValue
      ? getMinutes(defaultValue)
      : field.value
      ? getMinutes(field.value)
      : null,
  );

  useEffect(() => {
    const newValue =
      dateValue == null || hoursValue == null || minutesValue == null
        ? null
        : setMinutes(setHours(dateValue, hoursValue), minutesValue);
    if (newValue?.getTime() != (field.value as Date | null)?.getTime()) {
      field.onChange(newValue);
      if (onChange) onChange(newValue as PathValue<TFieldValues, TName>);
    }
  }, [field, onChange, dateValue, hoursValue, minutesValue]);

  const handleDateChange = (newDate: Date | null) => {
    setDateValue(newDate ? startOfDay(newDate) : null);
  };

  const handleTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const [h, m] = e.target.value
      ? e.target.value.split(':').map((v) => Number(v))
      : [null, null];
    setHoursValue(h);
    setMinutesValue(m);
  };

  let errorMessage;
  if (dateValue != null && (hoursValue == null || minutesValue == null)) {
    errorMessage = '時刻が入力されていません。';
  }
  if (dateValue == null && (hoursValue != null || minutesValue != null)) {
    errorMessage = '日付が入力されていません。';
  }

  if (errorMessage) {
    if (helperText) {
      helperText = (
        <>
          {errorMessage}
          <br />
          {helperText}
        </>
      );
    } else {
      helperText = errorMessage;
    }
  }

  return (
    <Box width="100%" mb={gutterBottom ? 2 : 0}>
      {label && (
        <FieldLabel
          label={label}
          required={required}
          hideRequiredMark={hideRequiredMark}
          tooltipContent={tooltipContent}
        />
      )}
      <DatePicker
        labelFunc={dateLabelFunc}
        value={dateValue}
        onChange={handleDateChange}
        inputVariant="outlined"
        clearable
        autoOk
        InputProps={{ style: { backgroundColor: grey[50] } }}
        inputProps={{ 'aria-label': label }}
        disabled={disabled}
        error={error}
        helperText={helperText}
        size="small"
        onBlur={field.onBlur}
      />
      <Box component="span" ml={1} />
      <TextField
        type="time"
        onChange={handleTimeChange}
        value={
          hoursValue != null && minutesValue != null
            ? `${padZero(hoursValue)}:${padZero(minutesValue)}`
            : ''
        }
        variant="outlined"
        InputProps={{ style: { backgroundColor: grey[50] } }}
        disabled={disabled}
        error={error}
        size="small"
        onBlur={field.onBlur}
      />
    </Box>
  );
};

const dateLabelFunc = (date: Date | null, invalidLabel: string) =>
  invalidLabel || formatDate(date, FormatDateOption.full);

const padZero = (n: number): string => {
  const s = '0' + n;
  return s.substring(s.length - 2);
};
