import { Box } from '@material-ui/core';
import {
  TodoActivityLogList_MemberFragment,
  TodoActivityLogList_LogFragment,
  TodoActivityType,
} from 'generated/graphql';
import { formatDateTime, FormatDateTimeOption } from 'utils/date';

type Props = {
  activityLog: TodoActivityLogList_LogFragment;
};

// コメント以外のアクション
export const Action = ({ activityLog }: Props): JSX.Element => {
  const targetMember = activityLog.activityTarget as
    | TodoActivityLogList_MemberFragment
    | null
    | undefined;

  return (
    <Box mb={3}>
      <Box>
        {getLogContent(
          activityLog.activityType,
          activityLog.member,
          targetMember,
        )}
      </Box>
      <Box fontSize="0.9em">
        {formatDateTime(activityLog.occurredAt, FormatDateTimeOption.slash)}
      </Box>
    </Box>
  );
};

function getLogContent(
  activityType: TodoActivityType,
  actorMember: TodoActivityLogList_MemberFragment | null | undefined,
  targetMember: TodoActivityLogList_MemberFragment | null | undefined,
) {
  const doneBy = actorMember ? `${actorMember.name}さんによって` : '';
  let content = '';
  switch (activityType) {
    case TodoActivityType.Created:
      content = 'ToDoが作成されました。';
      break;
    case TodoActivityType.Updated:
      content = '内容が更新されました。';
      break;
    case TodoActivityType.Assigned:
      if (targetMember) {
        content = `${targetMember.name}さんが担当者に設定されました。`;
      } else {
        content = '担当者が設定されました。';
      }
      break;
    case TodoActivityType.Unassigned:
      if (targetMember) {
        content = `${targetMember.name}さんが担当者から外されました。`;
      } else {
        content = '担当者が外されました。';
      }
      break;
    case TodoActivityType.Finished:
      content = 'ToDoが完了になりました。';
      break;
    case TodoActivityType.Unfinished:
      content = 'ToDoが未完了状態に戻されました。';
      break;
    case TodoActivityType.Deleted:
      content = '削除されました。';
      break;
  }
  return doneBy + content;
}
