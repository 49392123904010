import { companyContext as context } from 'apolloClient';
import {
  useEditTodoFormQuery,
  useUpdateTodoMutation,
  useSetPhaseToProjectTodoMutation,
} from 'generated/graphql';
import { useDisplaySnackbar } from 'hooks/useDisplaySnackbar';
import { Severity } from 'providers/SnackbarProvider/Severity';
import { DefaultValues } from 'react-hook-form';
import { TodoFormValues } from '../TodoForm/TodoFormValues';
import { buildDefaultValues } from './buildDefaultValues';

type Props = {
  id: string;
  onSubmit?: (id: string) => void;
};

type HookReturnType = {
  loading: boolean;
  loadError: Error | undefined;
  defaultValues: DefaultValues<TodoFormValues>;
  handleSubmit: (values: TodoFormValues, projectPhaseId: string | null) => void;
};

export const useEditTodoForm = ({ id, onSubmit }: Props): HookReturnType => {
  const { data, loading, error: loadError } = useEditTodoFormQuery({
    variables: { id },
    context,
  });

  const { show } = useDisplaySnackbar();

  const [updateTodo] = useUpdateTodoMutation({ context });
  const [setPhaseToProjectTodo] = useSetPhaseToProjectTodoMutation({ context });

  const todo = data?.companyGetTodo;

  const defaultValues = buildDefaultValues({ todo });

  const handleSubmit = async (
    values: TodoFormValues,
    projectPhaseId: string | null,
  ) => {
    const { title, description, assigneeIds, expiredAt } = values;
    const { data, errors } = await updateTodo({
      variables: {
        todoId: id,
        content: {
          title,
          description,
          assigneeIds,
          expiredAt: expiredAt ? expiredAt.toISOString() : undefined,
        },
      },
    });

    const { errors: setPhaseError } = await setPhaseToProjectTodo({
      variables: {
        todoId: id,
        projectPhaseId: projectPhaseId || undefined,
      },
    });

    if (setPhaseError) {
      show('エラーが発生しました', Severity.Error);
      throw setPhaseError[0];
    }

    const todo = data?.companyUpdateTodo.todo;

    if (errors) {
      show('エラーが発生しました', Severity.Error);
      throw errors[0];
    }

    if (!todo) {
      throw new Error('データが更新できませんでした');
    }

    if (onSubmit) onSubmit(todo.id);

    show('ToDoを更新しました', Severity.Success);
  };

  return {
    loading,
    loadError,
    defaultValues,
    handleSubmit,
  };
};
