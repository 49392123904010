import { useState } from 'react';
import {
  useTodoDialogQuery,
  TodoDetailPanel_TodoFragment,
} from 'generated/graphql';

const context = { clientName: 'company' };

type Props = {
  id: string;
  onClose: () => unknown;
  onChange: () => unknown;
};

type HookReturnType = {
  todo: TodoDetailPanel_TodoFragment | undefined;
  loading: boolean;
  loadError: Error | undefined;
  isEditMode: boolean;
  toggleEditMode: () => void;
  handleSubmit: () => void;
};

export const useTodoDialog = ({
  id,
  onClose,
  onChange,
}: Props): HookReturnType => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { data, error: loadError, loading } = useTodoDialogQuery({
    /* logの取得件数、個別のToDoに対するアクションなので概ね全権取得してページングはしない */
    variables: { id, logLimit: 60 },
    context,
  });
  const toggleEditMode = () => setIsEditMode(!isEditMode);
  const todo = data?.companyGetTodo;

  const handleSubmit = () => {
    onClose();
    onChange();
  };

  return {
    todo,
    loading,
    loadError,
    isEditMode,
    toggleEditMode,
    handleSubmit,
  };
};
