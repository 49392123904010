import { useState } from 'react';
import { useForm, Control } from 'react-hook-form';
import {
  useCustomerSelectDialog_CreateCustomerMutation,
  CustomerSelectDialog_CustomerFragment,
  CustomerType,
} from 'generated/graphql';
import type { FormValues } from './FormValues';
import { wrapUnknownError } from 'utils/wrapUnknownError';

type Props = {
  onCreate: (customer: CustomerSelectDialog_CustomerFragment) => unknown;
  includesContactInfo: boolean;
};

const context = { clientName: 'company' };

export const useNewCustomerForm = ({
  onCreate,
  includesContactInfo,
}: Props): {
  control: Control<FormValues>;
  handleSubmit: () => unknown;
  customerType: CustomerType;
  processing: boolean;
  processError: Error | undefined;
  isValid: boolean;
} => {
  const [processing, setProcessing] = useState(false);
  const [processError, setProcessError] = useState<Error>();
  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
  });
  const [createCustomer] = useCustomerSelectDialog_CreateCustomerMutation({
    context,
  });

  const onSubmit = async (values: FormValues) => {
    try {
      const { data, errors } = await createCustomer({
        variables: {
          createContent: {
            name: values.name,
            customerType: values.customerType,
            representativeName:
              values.customerType === CustomerType.Corporate
                ? values.representativeName
                : undefined,
            addresses: [],
            emails: [],
            contacts: [],
          },
          includesContactInfo,
        },
      });

      if (errors) {
        throw errors[0];
      }

      if (data) {
        onCreate(data?.companyCreateCustomer.customer);
      } else {
        throw new Error('Data is empty'); // should not reach here
      }
    } catch (err) {
      setProcessError(wrapUnknownError(err));
      setProcessing(false);
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    customerType: watch('customerType'),
    processing,
    processError,
    isValid,
  };
};
