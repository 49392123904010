import { TableCell as MuiTableCell, TableCellProps } from '@material-ui/core';
import { ReactChild } from 'react';

type Props = TableCellProps & {
  children: ReactChild;
};

export const TableCell = ({ children, ...props }: Props): JSX.Element => {
  const { width, ...rest } = props;

  return (
    <MuiTableCell component="div" {...rest} style={{ width }}>
      {children}
    </MuiTableCell>
  );
};
