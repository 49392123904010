import {
  Box,
  ListItem,
  ListItemIcon,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import { ProjectDetail_BasicInfo_SidePane_TodoFragment } from 'generated/graphql';
import {
  Person as PersonIcon,
  WatchLater as WatchLaterIcon,
} from '@material-ui/icons';
import { format } from 'date-fns';
import { TodoDialog } from 'components/todos/TodoDialog';

const useStyles = makeStyles((theme) => ({
  todoItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  todoTitle: {
    fontSize: 14,
  },
}));

type Props = {
  todo: ProjectDetail_BasicInfo_SidePane_TodoFragment;
  dialogOpen: boolean;
  onChange: () => unknown;
  onDialogOpen: (todoId: string) => void;
  onDialogClose: () => void;
};

export const Todo = ({
  todo,
  dialogOpen,
  onChange,
  onDialogOpen,
  onDialogClose,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <ListItem
        dense
        button
        className={classes.todoItem}
        onClick={() => onDialogOpen(todo.id)}
      >
        <ListItemIcon style={{ minWidth: 28 }}>
          <Checkbox
            edge="start"
            checked={todo.finishedAt != null}
            disableRipple
          />
        </ListItemIcon>
        <Box width="100%">
          <Box className={classes.todoTitle}>
            {todo.finishedAt ? <s>{todo.title}</s> : todo.title}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            fontSize="0.8rem"
            width="100%"
          >
            {todo.assignees && (
              <Box flex={1} display="flex" alignItems="center">
                <Box mr={0.5}>
                  <PersonIcon fontSize="inherit" />
                </Box>
                <Box>{todo.assignees.map((a) => a.name).join(', ')}</Box>
              </Box>
            )}
            {todo.expiredAt && (
              <Box display="flex" alignItems="center">
                <Box mr={0.5}>
                  <WatchLaterIcon fontSize="inherit" />
                </Box>
                <Box>{format(new Date(todo.expiredAt), 'M/d H:mm')}</Box>
              </Box>
            )}
          </Box>
        </Box>
      </ListItem>
      <TodoDialog
        id={todo.id}
        open={dialogOpen}
        onClose={onDialogClose}
        onChange={onChange}
      />
    </>
  );
};
