import { PolarViewBox } from 'recharts';

export const CustomLabel = ({
  viewBox,
  totalOrderedAmount,
}: {
  viewBox: PolarViewBox;
  totalOrderedAmount: number;
}): JSX.Element => {
  const { cx, cy } = viewBox;

  return (
    <>
      <text
        x={cx}
        y={cy}
        fill="#3d405c"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan dy="-0.3em" alignmentBaseline="middle" fontSize="16">
          {`合計 ${totalOrderedAmount.toLocaleString()}円`}
        </tspan>
      </text>
      <text
        x={cx}
        y={cy}
        fill="#3d405c"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
      ></text>
    </>
  );
};
