import { TextField as MuiTextField } from '@material-ui/core';
import { LoadError } from 'components/notification';
import { useProjectPhaseSelector } from './useProjectPhaseSelector';
import { Autocomplete } from '@material-ui/lab';
import { FieldLabel } from 'components/form';
import { Box } from '@material-ui/core/';

type Props = {
  label?: string;
  required?: boolean;
  hideRequiredMark?: boolean;
  gutterBottom?: boolean;
  value: string | null;
  onChange: (projectPhaseId: string | null) => void;
};

export const ProjectPhaseSelector = ({
  label,
  required = false,
  hideRequiredMark = false,
  gutterBottom = false,
  value,
  onChange,
}: Props) => {
  const {
    loading,
    loadError,
    options,
    selectedPhase,
    handleChange,
  } = useProjectPhaseSelector({ value, onChange });

  if (loadError) return <LoadError />;

  return (
    <Box mb={gutterBottom ? 2 : 0}>
      {label && (
        <FieldLabel
          label={label}
          required={required}
          hideRequiredMark={hideRequiredMark}
        />
      )}
      <Autocomplete
        value={selectedPhase}
        getOptionSelected={(
          option: { label: string; value: string },
          value: { label: string; value: string },
        ) => {
          return option.value === value.value;
        }}
        loading={loading}
        onChange={handleChange}
        options={options}
        getOptionLabel={(option: { label: string; value: string }) =>
          option.label
        }
        renderInput={(params) => (
          <MuiTextField {...params} variant="outlined" fullWidth size="small" />
        )}
      />
    </Box>
  );
};
