import type { ReactNode } from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(4),
    border: `${theme.palette.grey[300]} 1px solid`,
    borderRadius: theme.spacing(0.5),
    maxWidth: 1280,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      border: 'none',
      borderRadius: 0,
    },
  },
}));

export type ContainerProps = {
  children: ReactNode;
};

export const Container = ({ children }: ContainerProps): JSX.Element => {
  const classes = useStyles();
  return <Box className={classes.box}>{children}</Box>;
};
