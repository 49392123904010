import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import { getSession, signOut } from 'next-auth/client';
import config from 'config';
import graphqlTypes from 'generated/graphql';

import { buildClientSchema, IntrospectionQuery } from 'graphql';
import introspectionResult from '../graphql.schema.json';
import { withScalars } from 'apollo-link-scalars';

// note: sometimes it seems to be needed to cast it as Introspection Query
// `const schema = buildClientSchema((introspectionResult as unknown) as IntrospectionQuery)`
const schema = buildClientSchema(
  (introspectionResult as unknown) as IntrospectionQuery,
);

const scalarsLink = withScalars({
  schema,
  typesMap: {
    BigInt: {
      serialize: (parsed: bigint): string => parsed.toString(),
      parseValue: (raw: string): bigint => BigInt(raw),
    },
  },
});

// もしエンドポイントが複数になったらLinkを複数作って
// contextによって流す先を変える方法がある
// https://www.jamalx31.com/tech-posts/using-apollo-with-multiple-graphql-endpoints

const companyHttpLink = createHttpLink({
  uri: `${config.apiBaseUrl}/company/graphql`,
});

const insuranceAgencyHttpLink = createHttpLink({
  uri: `${config.apiBaseUrl}/insurance_agency/graphql`,
});

const crassoneHttpLink = createHttpLink({
  uri: `${config.apiBaseUrl}/crassone/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const session = await getSession();

  if (session?.error === 'RefreshAccessTokenError') {
    signOut(); // 強制ログアウト
  }

  if (session?.error === 'InvalidTokenError') {
    signOut(); // 強制ログアウト
  }

  const token = session?.token;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const directionalApolloLink = (): ApolloLink => {
  return ApolloLink.from([scalarsLink]).split(
    (operation) => operation.getContext().clientName === 'company',
    authLink.concat(companyHttpLink),
    ApolloLink.split(
      (operation) => operation.getContext().clientName === 'insuranceAgency',
      authLink.concat(insuranceAgencyHttpLink),
      authLink.concat(crassoneHttpLink),
    ),
  );
};

export const apolloClient = new ApolloClient({
  link: directionalApolloLink(),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          companyWholeProjects: relayStylePagination([
            'searchConditions',
            'sortCondition',
          ]),
        },
      },
      Member: {
        fields: {
          memberAccessPolicies: {
            merge: (
              _existingMemberAccessPolicies,
              incomingMemberAccessPolicies,
            ) => incomingMemberAccessPolicies,
          },
        },
      },
      Project: {
        fields: {
          tags: {
            merge: (_existingTags, incomingTags) => incomingTags,
          },
        },
      },
    },
    possibleTypes: graphqlTypes.possibleTypes,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export const companyContext = { clientName: 'company' } as const;
export const crassoneContext = { clientName: 'crassone' } as const;
export const insuranceAgencyContext = {
  clientName: 'insurance-agency',
} as const;
