import { forwardRef } from 'react';
import {
  Button,
  ButtonProps,
  CircularProgress,
  makeStyles,
  createStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      position: 'relative',
      display: 'inline',
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

export type ActionButtonProps = { processing?: boolean } & ButtonProps;

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  (
    {
      disabled = false,
      processing = false,
      children,
      ...rest
    }: ActionButtonProps,
    ref,
  ): JSX.Element => {
    const classes = useStyles();

    return (
      <div className={classes.wrapper}>
        <Button
          disableElevation
          disabled={disabled || processing}
          {...rest}
          ref={ref}
        >
          {children}
        </Button>
        {processing && (
          <CircularProgress size={24} className={classes.progress} />
        )}
      </div>
    );
  },
);

ActionButton.displayName = 'ActionButton';
