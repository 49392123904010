import { Ref } from 'react';
import { Box, TextFieldProps as MuiTextFieldProps } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { FieldValues, FieldPath } from 'react-hook-form';
import { FieldLabel } from '../FieldLabel';
import { BaseNumberField } from './BaseNumberField';
import {
  CommonFieldProps,
  mergeRequiredIntoRules,
} from '../common/CommonFieldProps';

// type="number" は使わない方がいいかも
// https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/

export type NumberFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = CommonFieldProps<TFieldValues, TName> & {
  placeholder?: string;
  inputProps?: MuiTextFieldProps['inputProps'];
  inputRef?: Ref<HTMLInputElement>;
  allowNegative?: boolean;
  maximumFractionDigits?: number;
};

export const NumberField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  control,
  name,
  label,
  hideRequiredMark,
  required,
  rules,
  gutterBottom,
  disabled,
  error,
  helperText,
  tooltipContent,
  containerRef,
  onChange,
  placeholder,
  inputProps,
  inputRef = undefined,
  allowNegative = false,
  maximumFractionDigits = undefined,
}: NumberFieldProps<TFieldValues, TName>): JSX.Element => {
  const mergedRules = mergeRequiredIntoRules(required, rules);

  return (
    <Box width="100%" mb={gutterBottom ? 2 : 0}>
      {label && (
        <FieldLabel
          label={label}
          required={!!mergedRules?.required}
          hideRequiredMark={hideRequiredMark}
          tooltipContent={tooltipContent}
        />
      )}
      <BaseNumberField
        control={control}
        name={name}
        rules={mergedRules}
        variant="outlined"
        fullWidth
        InputProps={{ style: { backgroundColor: grey[50] } }}
        inputProps={{ 'aria-label': label, ...inputProps }}
        disabled={disabled}
        error={error}
        helperText={helperText}
        containerRef={containerRef}
        onChange={onChange}
        type="text"
        placeholder={placeholder}
        inputRef={inputRef}
        size="small"
        allowNegative={allowNegative}
        maximumFractionDigits={maximumFractionDigits}
      />
    </Box>
  );
};
