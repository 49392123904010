import { Box, Button, Divider, makeStyles } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxBlankIcon,
} from '@material-ui/icons';
import { ExternalLinkButton } from 'components/buttons';
import { format } from 'date-fns';
import { AttributesTable } from 'components/AttributesTable';
import { borderGrey } from 'color-palette';
import { TodoDetailPanel_TodoFragment } from 'generated/graphql';
import { useTodoDetailPanel } from './useTodoDetailPanel';
import { TodoActivityLogList } from './components/TodoActivityLogList';
import { CommentForm } from './components/CommentForm';

const useStyles = makeStyles(() => ({
  finishStatus: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
}));

type Props = {
  todo: TodoDetailPanel_TodoFragment;
  toggleEditMode: () => unknown;
  onDeleted?: () => void;
};

export const TodoDetailPanel = ({
  todo,
  toggleEditMode,
  onDeleted,
}: Props): JSX.Element => {
  const classes = useStyles();
  const {
    handleFinish,
    handleUnfinish,
    handleDelete,
    toggleHideExceptComments,
    displayAllTodoActivityLogs,
  } = useTodoDetailPanel({
    id: todo.id,
    onDeleted,
  });

  const attributes = [
    {
      label: '案件名',
      value: todo.project?.title && (
        <ExternalLinkButton href={`/projects/${todo.project.id}`}>
          {todo.project.title}
        </ExternalLinkButton>
      ),
    },
    { label: '作成者', value: todo.creator.name },
    {
      label: '担当者',
      value:
        todo.assignees &&
        todo.assignees.map((assignee) => assignee.name).join(', '),
    },
    {
      label: '期限',
      value: todo.expiredAt && format(new Date(todo.expiredAt), 'M/d H:mm'),
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Box textAlign="right" mx={1}>
          <Button
            startIcon={<EditIcon />}
            color="default"
            variant="outlined"
            onClick={toggleEditMode}
          >
            編集
          </Button>
        </Box>
        <Box textAlign="right" mx={1}>
          <Button
            startIcon={<DeleteIcon />}
            color="default"
            variant="outlined"
            onClick={handleDelete}
          >
            削除
          </Button>
        </Box>
      </Box>
      {todo.description && (
        <Box
          my={2}
          p={2}
          whiteSpace="pre-line"
          border={`1px solid ${borderGrey}`}
        >
          {todo.description}
        </Box>
      )}
      <AttributesTable data={attributes} />
      <Box my={2}>
        {todo.finishedAt ? (
          <Box display="flex" alignItems="center">
            <Box mr={2} className={classes.finishStatus}>
              <CheckBoxIcon color="secondary" /> 完了{' '}
              {todo.finishedAt && format(new Date(todo.finishedAt), 'M/d H:mm')}
            </Box>
            <Button color="default" variant="outlined" onClick={handleUnfinish}>
              「未完了」に戻す
            </Button>
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <Box mr={2} className={classes.finishStatus}>
              <CheckBoxBlankIcon color="secondary" /> 未完了
            </Box>
            <Button color="default" variant="outlined" onClick={handleFinish}>
              「完了」にする
            </Button>
          </Box>
        )}
      </Box>
      <Divider />
      <CommentForm todoId={todo.id} />
      <Box my={2}>
        <TodoActivityLogList
          todo={todo}
          toggleHideExceptComments={toggleHideExceptComments}
          displayAllTodoActivityLogs={displayAllTodoActivityLogs}
        />
      </Box>
    </>
  );
};
