import { useState } from 'react';
import { DefaultValues, useForm, UseFormReturn } from 'react-hook-form';
import { wrapUnknownError } from 'utils/wrapUnknownError';
import { TodoFormValues } from './TodoFormValues';
import { existenceFilter } from 'utils/type-filter';

type Props = {
  defaultValues: DefaultValues<TodoFormValues>;
  onSubmit: (
    formValues: TodoFormValues,
    projectPhaseId: string | null,
  ) => unknown;
};

type HookReturnType = {
  formMethods: UseFormReturn<TodoFormValues>;
  isFormValid: boolean;
  processing: boolean;
  processError: Error | undefined;
  selectedMemberIds: string[];
  selectedProjectPhaseId: string | null;
  handleAssigneeChange: (assigneeIds: string[]) => void;
  handleProjectPhaseChange: (projectPhaseId: string | null) => void;
  handleSubmit: () => void;
};

export const useTodoForm = ({
  defaultValues,
  onSubmit,
}: Props): HookReturnType => {
  const [processing, setProcessing] = useState(false);
  const [processError, setProcessError] = useState<Error>();
  const [projectPhaseId, setProjectPhaseId] = useState<string | null>(
    defaultValues.projectPhaseId ?? null,
  );

  const formMethods = useForm<TodoFormValues>({
    mode: 'onChange',
    defaultValues,
  });
  const {
    formState: { isValid: isFormValid },
    handleSubmit: rhfHandleSubmit,
  } = formMethods;

  const selectedMemberIds = defaultValues.assigneeIds
    ? defaultValues.assigneeIds.filter(existenceFilter)
    : [];

  const selectedProjectPhaseId = defaultValues.projectPhaseId ?? null;

  const handleAssigneeChange = (assigneeIds: string[]) => {
    formMethods.setValue('assigneeIds', assigneeIds);
  };

  const handleProjectPhaseChange = (projectPhaseId: string | null) => {
    setProjectPhaseId(projectPhaseId);
  };

  const handleSubmit = rhfHandleSubmit(async (values: TodoFormValues) => {
    try {
      setProcessing(true);
      await onSubmit(values, projectPhaseId);
    } catch (err) {
      setProcessError(wrapUnknownError(err));
      setProcessing(false); // 成功したらページ遷移する前提なのでエラーの時だけ戻す
    }
  });

  return {
    formMethods,
    isFormValid,
    processing,
    processError,
    selectedMemberIds,
    selectedProjectPhaseId,
    handleAssigneeChange,
    handleProjectPhaseChange,
    handleSubmit,
  };
};
