import { SalesSummaryResult } from 'generated/graphql';
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  Bar,
  ResponsiveContainer,
} from 'recharts';

const formatter = (value: string | number | (string | number)[]) => {
  return value.toLocaleString();
};

const salesAmountformatter = (value: number) => {
  return new Intl.NumberFormat('ja-JP', { notation: 'compact' }).format(value);
};

type Props = {
  salesSummary: (Omit<SalesSummaryResult, 'orderedAmount' | 'salesAmount'> & {
    orderedAmount: number;
    salesAmount: number;
  })[];
};

export const SalesSummaryChart = ({ salesSummary }: Props): JSX.Element => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart
        data={salesSummary}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="2 6" />
        <XAxis
          dataKey="month"
          minTickGap={1}
          angle={-20}
          tickSize={10}
          tickMargin={15}
          height={40}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          stroke="#333"
          unit="件"
          tickFormatter={formatter}
        />
        <YAxis
          yAxisId="left"
          orientation="left"
          stroke="#333"
          type="number"
          unit="円"
          width={100}
          tickFormatter={salesAmountformatter}
        />
        <YAxis yAxisId="left" axisLine={false} tickLine={false} tickCount={0} />
        <Tooltip
          formatter={formatter}
          labelStyle={{ marginBottom: '5px', borderBottom: '1px solid #eee' }}
          wrapperStyle={{ minWidth: '180px' }}
        />
        <Legend verticalAlign="top" height={32} />
        <Bar
          yAxisId="left"
          dataKey="salesAmount"
          barSize={15}
          fill="#12a"
          name="売上高"
          unit="円"
        />
        <Bar
          yAxisId="left"
          dataKey="orderedAmount"
          barSize={15}
          fill="#ff0077"
          name="受注高"
          unit="円"
        />
        <Line
          type="monotone"
          yAxisId="right"
          dataKey="contractsCount"
          stroke="#3faf05"
          name="受注数"
          unit="件"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
